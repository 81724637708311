import { jsx as _jsx } from "react/jsx-runtime";
import { NotFoundPage } from '@common/ui/not-found-page/not-found-page';
import { LinkPageRenderer } from '@app/short-links/renderers/link-page-renderer';
import { useState } from 'react';
import { LinkOverlayRenderer } from '@app/short-links/renderers/link-overlay-renderer';
import { LinkIframeRenderer } from '@app/short-links/renderers/link-iframe-renderer';
import { LinkSplashRenderer } from '@app/short-links/renderers/link-splash-renderer';
import { LinkGroupRenderer } from '@app/short-links/renderers/link-group-renderer';
import { BiolinkRenderer } from '@app/short-links/renderers/biolink-renderer/biolink-renderer';
import { PasswordPage } from '@app/short-links/password-page';
export function LinkeableRenderer({ linkeable }) {
    const [passwordValid, setPasswordValid] = useState(!linkeable.has_password);
    if (linkeable.has_password && !passwordValid) {
        return (_jsx(PasswordPage, { linkeable: linkeable, onPasswordValid: () => setPasswordValid(true) }));
    }
    return getLinkeableRenderer(linkeable);
}
function getLinkeableRenderer(linkeable) {
    switch (linkeable.model_type) {
        case 'link':
            return getLinkRenderer(linkeable);
        case 'linkGroup':
            return _jsx(LinkGroupRenderer, { linkGroup: linkeable });
        case 'biolink':
            return _jsx(BiolinkRenderer, { biolink: linkeable });
        default:
            return _jsx(NotFoundPage, {});
    }
}
function getLinkRenderer(link) {
    switch (link.type) {
        case 'frame':
            return _jsx(LinkIframeRenderer, { link: link });
        case 'overlay':
            return _jsx(LinkOverlayRenderer, { link: link });
        case 'splash':
            return _jsx(LinkSplashRenderer, { link: link });
        case 'page':
            return _jsx(LinkPageRenderer, { link: link });
        case 'direct':
            window.location.replace(link.long_url);
            return null;
        default:
            return _jsx(NotFoundPage, {});
    }
}
