import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { toast } from '../../ui/toast/toast';
import { apiClient, queryClient } from '../../http/query-client';
import { WorkspaceQueryKeys } from './workspace-query-keys';
import { useActiveWorkspaceId } from '../active-workspace-id-context';
import { useUserNotifications } from '../../notifications/dialog/requests/user-notifications';
import { message } from '../../i18n/message';
import { showHttpErrorToast } from '../../utils/http/show-http-error-toast';
export function useJoinWorkspace() {
    const { setWorkspaceId } = useActiveWorkspaceId() || {};
    return useMutation({
        mutationFn: (props) => joinWorkspace(props),
        onSuccess: response => {
            toast(message('Joined workspace'));
            setWorkspaceId(response.workspace.id);
            queryClient.invalidateQueries({
                queryKey: WorkspaceQueryKeys.fetchUserWorkspaces,
            });
            queryClient.invalidateQueries({ queryKey: useUserNotifications.key });
        },
        onError: e => {
            if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
                queryClient.invalidateQueries({ queryKey: useUserNotifications.key });
                toast.danger(message('This invite is no longer valid'));
            }
            else {
                showHttpErrorToast(e);
            }
        },
    });
}
function joinWorkspace({ inviteId }) {
    return apiClient.get(`workspace/join/${inviteId}`).then(r => r.data);
}
