import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { clamp } from '../../utils/number/clamp';
import { useNumberFormatter } from '../../i18n/use-number-formatter';
export const ProgressCircle = React.forwardRef((props, ref) => {
    let { value = 0, minValue = 0, maxValue = 100, size = 'md', isIndeterminate = false, className, position = 'relative', trackColor, fillColor = 'border-primary', ...domProps } = props;
    value = clamp(value, minValue, maxValue);
    const circleSize = getCircleStyle(size);
    const percentage = (value - minValue) / (maxValue - minValue);
    const formatter = useNumberFormatter({ style: 'percent' });
    let valueLabel = '';
    if (!isIndeterminate && !valueLabel) {
        valueLabel = formatter.format(percentage);
    }
    const subMask1Style = {};
    const subMask2Style = {};
    if (!isIndeterminate) {
        const percentage = ((value - minValue) / (maxValue - minValue)) * 100;
        let angle;
        if (percentage > 0 && percentage <= 50) {
            angle = -180 + (percentage / 50) * 180;
            subMask1Style.transform = `rotate(${angle}deg)`;
            subMask2Style.transform = 'rotate(-180deg)';
        }
        else if (percentage > 50) {
            angle = -180 + ((percentage - 50) / 50) * 180;
            subMask1Style.transform = 'rotate(0deg)';
            subMask2Style.transform = `rotate(${angle}deg)`;
        }
    }
    return (_jsxs("div", { ...domProps, "aria-valuenow": isIndeterminate ? undefined : value, "aria-valuemin": minValue, "aria-valuemax": maxValue, "aria-valuetext": isIndeterminate ? undefined : valueLabel, role: "progressbar", ref: ref, className: clsx('progress-circle', position, circleSize, isIndeterminate && 'indeterminate', className), children: [_jsx("div", { className: clsx(circleSize, trackColor, 'rounded-full border-4') }), _jsxs("div", { className: clsx('fills absolute left-0 top-0 h-full w-full', isIndeterminate && 'progress-circle-fills-animate'), children: [_jsx(FillMask, { circleSize: circleSize, subMaskStyle: subMask1Style, isIndeterminate: isIndeterminate, className: "rotate-180", fillColor: fillColor, subMaskClassName: clsx(isIndeterminate && 'progress-circle-fill-submask-1-animate') }), _jsx(FillMask, { circleSize: circleSize, subMaskStyle: subMask2Style, isIndeterminate: isIndeterminate, fillColor: fillColor, subMaskClassName: clsx(isIndeterminate && 'progress-circle-fill-submask-2-animate') })] })] }));
});
function FillMask({ subMaskStyle, subMaskClassName, className, circleSize, isIndeterminate, fillColor, }) {
    return (_jsx("div", { className: clsx('absolute h-full w-1/2 origin-[100%] overflow-hidden', className), children: _jsx("div", { className: clsx('h-full w-full origin-[100%] rotate-180 overflow-hidden', !isIndeterminate && 'transition-transform duration-100', subMaskClassName), style: subMaskStyle, children: _jsx("div", { className: clsx(circleSize, fillColor, 'rounded-full border-4') }) }) }));
}
function getCircleStyle(size) {
    switch (size) {
        case 'xs':
            return 'w-20 h-20';
        case 'sm':
            return 'w-24 h-24';
        case 'md':
            return 'w-32 h-32';
        case 'lg':
            return 'w-42 h-42';
        default:
            return size;
    }
}
