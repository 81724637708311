export var WidgetType;
(function (WidgetType) {
    WidgetType["Image"] = "image";
    WidgetType["Text"] = "text";
    WidgetType["Socials"] = "socials";
    WidgetType["Youtube"] = "youtube";
    WidgetType["Soundcloud"] = "soundcloud";
    WidgetType["Vimeo"] = "video";
    WidgetType["Spotify"] = "spotify";
    WidgetType["Twitch"] = "twitch";
    WidgetType["Tiktok"] = "tiktok";
})(WidgetType || (WidgetType = {}));
