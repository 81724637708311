import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import React from 'react';
import { CheckIcon } from '../../icons/material/Check';
export const ListItemBase = React.forwardRef((props, ref) => {
    let { startIcon, capitalizeFirst, children, description, endIcon, endSection, isDisabled, isActive, isSelected, showCheckmark, elementType = 'div', radius, padding, ...domProps } = props;
    if (!startIcon && showCheckmark) {
        startIcon = (_jsx(CheckIcon, { size: "sm", className: clsx('text-primary', !isSelected && 'invisible') }));
    }
    // if (!endIcon && !endSection && showCheckmark) {
    //   endIcon = (
    //     <CheckIcon size="sm" className={clsx('text-primary', 'invisible')} />
    //   );
    // }
    const iconClassName = clsx('icon-sm rounded overflow-hidden flex-shrink-0', !isDisabled && 'text-muted');
    const endSectionClassName = clsx(!isDisabled && 'text-muted');
    const Element = elementType;
    return (_jsxs(Element, { ...domProps, "aria-disabled": isDisabled, className: itemClassName(props), ref: ref, children: [startIcon && _jsx("div", { className: iconClassName, children: startIcon }), _jsxs("div", { className: clsx('min-w-auto mr-auto w-full overflow-hidden overflow-ellipsis', capitalizeFirst && 'first-letter:capitalize'), children: [children, description && (_jsx("div", { className: clsx('mt-4 whitespace-normal text-xs', isDisabled ? 'text-disabled' : 'text-muted'), children: description }))] }), (endIcon || endSection) && (_jsx("div", { className: endIcon ? iconClassName : endSectionClassName, children: endIcon || endSection }))] }));
});
function itemClassName({ className, isSelected, isActive, isDisabled, showCheckmark, endIcon, endSection, radius, padding: userPadding, }) {
    let state = '';
    if (isDisabled) {
        state = 'text-disabled pointer-events-none';
    }
    else if (isSelected) {
        if (isActive) {
            state = 'bg-primary/focus';
        }
        else {
            state = 'bg-primary/selected hover:bg-primary/focus';
        }
    }
    else if (isActive) {
        state = 'hover:bg-fg-base/15 bg-focus';
    }
    else {
        state = 'hover:bg-hover';
    }
    let padding;
    if (userPadding) {
        padding = userPadding;
    }
    else if (showCheckmark) {
        if (endIcon || endSection) {
            padding = 'pl-8 pr-8 py-8';
        }
        else {
            padding = 'pl-8 pr-24 py-8';
        }
    }
    else {
        padding = 'px-20 py-8';
    }
    return clsx('w-full select-none outline-none cursor-pointer', 'text-sm truncate flex items-center gap-10', !isDisabled && 'text-main', padding, state, className, radius);
}
