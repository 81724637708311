import {User} from '@common/auth/user';
import {useDisableTwoFactor} from '@common/auth/ui/two-factor/requests/use-disable-two-factor';
import {useRegenerateTwoFactorCodes} from '@common/auth/ui/two-factor/requests/use-regenerate-two-factor-codes';
import {Fragment} from 'react';
import {queryClient} from '@common/http/query-client';
import {Trans} from '@common/i18n/trans';
import {TwoFactorStepperLayout} from '@common/auth/ui/two-factor/stepper/two-factor-stepper-layout';
import {toast} from '@common/ui/toast/toast';
import {message} from '@common/i18n/message';
import {usePasswordConfirmedAction} from '@common/auth/ui/confirm-password/use-password-confirmed-action';
import {Button} from '@common/ui/buttons/button';

interface Props {
  user: User;
  onDisabled: () => void;
}
export function TwoFactorEnabledStep({user, onDisabled}: Props) {
  const disableTwoFactor = useDisableTwoFactor();
  const regenerateCodes = useRegenerateTwoFactorCodes();
  const {withConfirmedPassword, isLoading: confirmPasswordIsLoading} =
    usePasswordConfirmedAction();
  const isLoading =
    disableTwoFactor.isPending ||
    regenerateCodes.isPending ||
    confirmPasswordIsLoading;

  const actions = (
    <Fragment>
      {/* <Button
        type="button"
        onClick={() =>
          withConfirmedPassword(() => {
            regenerateCodes.mutate(undefined, {
              onSuccess: () => {
                queryClient.invalidateQueries({queryKey: ['users']});
              },
            });
          })
        }
        variant="outline"
        disabled={isLoading}
        className="mr-12"
      >
        <Trans message="Kurtarma kodlarını yeniden oluştur" />
      </Button> */}
      {/* <Button
        type="submit"
        variant="flat"
        color="danger"
        disabled={isLoading}
        onClick={() => {
          withConfirmedPassword(() => {
            disableTwoFactor.mutate(undefined, {
              onSuccess: () => {
                toast(message('Two factor authentication has been disabled.'));
                onDisabled();
              },
            });
          });
        }}
      >
        <Trans message="Disable" />
      </Button> */}

      <input onClick={() => {
          withConfirmedPassword(() => {
            disableTwoFactor.mutate(undefined, {
              onSuccess: () => {
                toast(message('İki faktörlü kimlik doğrulama devre dışı bırakıldı.'));
                onDisabled();
              },
            });
          });
        }} className="absolute left-0 top-0 h-24 w-24 appearance-none overflow-hidden rounded outline-none ring-inset transition-shadow focus-visible:ring" type="checkbox" aria-checked="false" name="2factorcheckbox" checked value=""/>
    </Fragment>
  );

  return (
    <TwoFactorStepperLayout
      title={<Trans message="İki faktörlü kimlik doğrulamayı etkinleştirdiniz." />}
      description={
        <Trans message="Bu kurtarma kodlarını güvenli bir parola yöneticisinde saklayın. İki faktörlü kimlik doğrulama aygıtınız kaybolursa hesabınıza erişimi kurtarmak için kullanabilirsiniz." />
      }
      actions={actions}
    >
      <div className="bg-alt p-14 font-mono text-sm mb-16 rounded">
        {Array.isArray(user.two_factor_recovery_codes) ? (
          user.two_factor_recovery_codes.map(code => (
            <div className="mb-4" key={code}>
                {code}
            </div>
          ))
        ) : (
        <p>No recovery codes found.</p>
        )}
      </div>
    </TwoFactorStepperLayout>
  );
}
