import { encode } from './util';
import SpotifyUri from './spotify-uri';
export default class Search extends SpotifyUri {
    type = 'search';
    query;
    constructor(uri, query) {
        super(uri);
        this.query = query;
    }
    static is(v) {
        return Boolean(typeof v === 'object' && v.type === 'search');
    }
    toURI() {
        return `spotify:search:${encode(this.query)}`;
    }
    toURL() {
        return `/search/${encode(this.query)}`;
    }
}
