import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClientProvider } from '@tanstack/react-query';
import { domAnimation, LazyMotion } from 'framer-motion';
import { queryClient } from '../http/query-client';
import { SiteConfigContext } from './settings/site-config-context';
import { SiteConfig } from '@app/site-config';
import deepMerge from 'deepmerge';
import { BaseSiteConfig } from './settings/base-site-config';
import { ThemeProvider } from './theme-provider';
import { BootstrapDataProvider } from './bootstrap-data/bootstrap-data-provider';
const mergedConfig = deepMerge(BaseSiteConfig, SiteConfig);
export function CommonProvider({ children }) {
    return (_jsx(QueryClientProvider, { client: queryClient, children: _jsx(LazyMotion, { features: domAnimation, children: _jsx(SiteConfigContext.Provider, { value: mergedConfig, children: _jsx(BootstrapDataProvider, { children: _jsx(ThemeProvider, { children: children }) }) }) }) }));
}
