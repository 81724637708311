import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RegisterPage } from './ui/register-page';
import { AuthRoute } from './guards/auth-route';
import { AccountSettingsPage } from './ui/account-settings/account-settings-page';
import { GuestRoute } from './guards/guest-route';
import { ForgotPasswordPage } from './ui/forgot-password-page';
import { ResetPasswordPage } from './ui/reset-password-page';
import { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { LoginPageWrapper } from '@common/auth/ui/login-page-wrapper';
export const AuthRoutes = (_jsxs(Fragment, { children: [_jsx(Route, { path: "/register", element: _jsx(RegisterPage, {}) }), _jsx(Route, { path: "/account-settings", element: _jsx(AuthRoute, { children: _jsx(AccountSettingsPage, {}) }) }), _jsx(Route, { path: "login", element: _jsx(GuestRoute, { children: _jsx(LoginPageWrapper, {}) }) }), _jsx(Route, { path: "/workspace/join/register", element: _jsx(GuestRoute, { children: _jsx(RegisterPage, {}) }) }), _jsx(Route, { path: "/workspace/join/login", element: _jsx(GuestRoute, { children: _jsx(LoginPageWrapper, {}) }) }), _jsx(Route, { path: "forgot-password", element: _jsx(GuestRoute, { children: _jsx(ForgotPasswordPage, {}) }) }), _jsx(Route, { path: "/password/reset/:token", element: _jsx(GuestRoute, { children: _jsx(ResetPasswordPage, {}) }) })] }));
