import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, m } from 'framer-motion';
import clsx from 'clsx';
import { IconButton } from '../buttons/icon-button';
import { CloseIcon } from '../../icons/material/Close';
import { MixedText } from '../../i18n/mixed-text';
import { Button } from '../buttons/button';
import { toastState, useToastStore } from './toast-store';
import { Link } from 'react-router-dom';
import { ErrorOutlineIcon } from '../../icons/material/ErrorOutline';
import { CheckCircleIcon } from '../../icons/material/CheckCircle';
import { ProgressCircle } from '@common/ui/progress/progress-circle';
const initial = { opacity: 0, y: 50, scale: 0.3 };
const animate = { opacity: 1, y: 0, scale: 1 };
const exit = {
    opacity: 0,
    scale: 0.5,
};
export function ToastContainer() {
    const toasts = useToastStore(s => s.toasts);
    return (_jsx("div", { className: "relative pointer-events-none", children: _jsx(AnimatePresence, { initial: false, children: toasts.map(toast => (_jsx("div", { className: clsx('fixed mx-auto p-20 z-toast', toast.position === 'bottom-center'
                    ? 'left-0 right-0 bottom-0'
                    : 'right-0 bottom-0'), children: _jsxs(m.div, { initial: toast.disableEnterAnimation ? undefined : initial, animate: toast.disableEnterAnimation ? undefined : animate, exit: toast.disableExitAnimation ? undefined : exit, className: clsx('flex items-center gap-10 min-w-288 max-w-500 shadow-lg w-min rounded-lg pl-16 pr-6 py-6 text-sm pointer-events-auto max-h-100 bg-paper text-main bg-paper border mx-auto min-h-50'), onPointerEnter: () => toast.timer?.pause(), onPointerLeave: () => toast.timer?.resume(), role: "alert", "aria-live": toast.type === 'danger' ? 'assertive' : 'polite', children: [toast.type === 'danger' && (_jsx(ErrorOutlineIcon, { className: "text-danger flex-shrink-0", size: "md" })), toast.type === 'loading' && (_jsx(ProgressCircle, { size: "sm", className: "flex-shrink-0", isIndeterminate: true })), toast.type === 'positive' && (_jsx(CheckCircleIcon, { className: "text-positive flex-shrink-0", size: "md" })), _jsx("div", { className: "overflow-hidden overflow-ellipsis w-max mr-auto", "data-testid": "toast-message", children: _jsx(MixedText, { value: toast.message }) }), toast.action && (_jsx(Button, { variant: "text", color: "primary", size: "sm", className: "flex-shrink-0", onFocus: () => toast.timer?.pause(), onBlur: () => toast.timer?.resume(), onClick: () => toastState().remove(toast.id), elementType: Link, to: toast.action.action, children: _jsx(MixedText, { value: toast.action.label }) })), toast.type !== 'loading' && (_jsx(IconButton, { onFocus: () => toast.timer?.pause(), onBlur: () => toast.timer?.resume(), type: "button", className: "flex-shrink-0", onClick: () => {
                                toastState().remove(toast.id);
                            }, size: "sm", children: _jsx(CloseIcon, {}) }))] }) }, toast.id))) }) }));
}
