import { jsx as _jsx } from "react/jsx-runtime";
import { useSettings } from '@common/core/settings/use-settings';
import useClipboard from 'react-use-clipboard';
import { IconButton } from '@common/ui/buttons/icon-button';
import { Tooltip } from '@common/ui/tooltip/tooltip';
import { Trans } from '@common/i18n/trans';
import { toast } from '@common/ui/toast/toast';
import { message } from '@common/i18n/message';
import { CopyLinkIcon } from './copy-link-icon';
import { removeProtocol } from '@common/utils/urls/remove-protocol';
import { ButtonBase } from '@common/ui/buttons/button-base';
export function LinkClipboardButton({ link, variant, ...domProps }) {
    const { base_url } = useSettings();
    let url;
    if ('short_url' in link && link.short_url) {
        url = link.short_url;
    }
    else {
        url = `${base_url}/${link.hash}`;
    }
    const [, setCopied] = useClipboard(url);
    if (variant === 'text') {
        return (_jsx(ButtonBase, { ...domProps, onClick: () => {
                setCopied();
                toast.positive(message('Copied to clipboard'));
            }, children: removeProtocol(url) }));
    }
    return (_jsx(Tooltip, { label: _jsx(Trans, { message: "Copy to clipboard" }), children: _jsx(IconButton, { ...domProps, onClick: () => {
                setCopied();
                toast.positive(message('Copied to clipboard'));
            }, children: _jsx(CopyLinkIcon, {}) }) }));
}
