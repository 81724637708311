import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { FocusScope, useFocusManager } from '@react-aria/focus';
import { ListItemBase } from './list-item-base';
import clsx from 'clsx';
export function List({ children, className, padding, dataTestId }) {
    return (_jsx(FocusScope, { children: _jsx("ul", { "data-testid": dataTestId, className: clsx('text-base outline-none sm:text-sm', className, padding ?? 'py-4'), children: children }) }));
}
export const ListItem = forwardRef(({ children, onSelected, borderRadius = 'rounded', className, ...listItemProps }, ref) => {
    const focusManager = useFocusManager();
    const isSelectable = !!onSelected;
    const [isActive, setIsActive] = useState(false);
    const onKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                focusManager?.focusNext();
                break;
            case 'ArrowUp':
                e.preventDefault();
                focusManager?.focusPrevious();
                break;
            case 'Home':
                e.preventDefault();
                focusManager?.focusFirst();
                break;
            case 'End':
                e.preventDefault();
                focusManager?.focusLast();
                break;
            case 'Enter':
            case 'Space':
                e.preventDefault();
                onSelected?.();
                break;
        }
    };
    return (_jsx("li", { children: _jsx(ListItemBase, { className: clsx(className, borderRadius), isActive: isActive, isDisabled: listItemProps.isDisabled, ...listItemProps, onFocus: e => {
                setIsActive(e.target.matches(':focus-visible'));
            }, onBlur: () => {
                setIsActive(false);
            }, onClick: () => {
                onSelected?.();
            }, ref: ref, role: isSelectable ? 'button' : undefined, onKeyDown: isSelectable ? onKeyDown : undefined, tabIndex: isSelectable && !listItemProps.isDisabled ? 0 : undefined, children: children }) }));
});
