import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, memo } from 'react';
import { useNumberFormatter } from './use-number-formatter';
import { shallowEqual } from '../utils/shallow-equal';
export const FormattedNumber = memo(({ value, ...options }) => {
    const formatter = useNumberFormatter(options);
    if (isNaN(value)) {
        value = 0;
    }
    return _jsx(Fragment, { children: formatter.format(value) });
}, shallowEqual);
