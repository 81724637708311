import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { createSvgIconFromTree } from '../icons/create-svg-icon';
import { useCustomMenu } from './use-custom-menu';
import { Trans } from '../i18n/trans';
export function CustomMenu({ className, iconClassName, itemClassName, gap = 'gap-30', menu: menuOrPosition, orientation = 'horizontal', children, matchDescendants, onlyShowIcons, iconSize, unstyled = false, }) {
    const menu = useCustomMenu(menuOrPosition);
    if (!menu)
        return null;
    return (_jsx("div", { className: clsx('flex', gap, orientation === 'vertical' ? 'flex-col items-start' : 'items-center', className), "data-menu-id": menu.id, children: menu.items.map(item => {
            if (children) {
                return children(item);
            }
            return (_jsx(CustomMenuItem, { unstyled: unstyled, onlyShowIcon: onlyShowIcons, matchDescendants: matchDescendants, iconClassName: iconClassName, iconSize: iconSize, className: props => {
                    return typeof itemClassName === 'function'
                        ? itemClassName({ ...props, item })
                        : itemClassName;
                }, item: item }, item.id));
        }) }));
}
export const CustomMenuItem = forwardRef(({ item, className, matchDescendants, unstyled, onlyShowIcon, iconClassName, iconSize = 'sm', ...linkProps }, ref) => {
    const label = _jsx(Trans, { message: item.label });
    const Icon = item.icon && createSvgIconFromTree(item.icon);
    const content = (_jsxs(Fragment, { children: [Icon && _jsx(Icon, { size: iconSize, className: iconClassName }), (!Icon || !onlyShowIcon) && label] }));
    const baseClassName = !unstyled && 'whitespace-nowrap flex items-center justify-start gap-10';
    const focusClassNames = !unstyled && 'outline-none focus-visible:ring-2';
    if (item.type === 'link') {
        return (_jsx("a", { className: clsx(baseClassName, className?.({ isActive: false }), focusClassNames), href: item.action, target: item.target, "data-menu-item-id": item.id, ref: ref, ...linkProps, children: content }));
    }
    return (_jsx(NavLink, { end: typeof matchDescendants === 'function'
            ? matchDescendants(item.action)
            : matchDescendants, className: props => clsx(baseClassName, className?.(props), focusClassNames), to: item.action, target: item.target, "data-menu-item-id": item.id, ref: ref, ...linkProps, children: content }));
});
