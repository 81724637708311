import { encode } from './util';
import SpotifyUri from './spotify-uri';
export default class Playlist extends SpotifyUri {
    type = 'playlist';
    id;
    user;
    constructor(uri, id, user) {
        super(uri);
        this.id = id;
        if (typeof user === 'string') {
            this.user = user;
        }
    }
    static is(v) {
        return Boolean(typeof v === 'object' && v.type === 'playlist');
    }
    toURI() {
        if (this.user !== undefined) {
            if (this.id === 'starred') {
                return `spotify:user:${encode(this.user)}:${encode(this.id)}`;
            }
            return `spotify:user:${encode(this.user)}:playlist:${encode(this.id)}`;
        }
        return `spotify:playlist:${encode(this.id)}`;
    }
    toURL() {
        if (this.user !== undefined) {
            if (this.id === 'starred') {
                return `/user/${encode(this.user)}/${encode(this.id)}`;
            }
            return `/user/${encode(this.user)}/playlist/${encode(this.id)}`;
        }
        return `/playlist/${encode(this.id)}`;
    }
}
