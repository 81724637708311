import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { ImageIcon } from '@common/icons/material/Image';
export function ImageWidgetRenderer({ widget, variant, }) {
    const image = widget.config.url ? (_jsx("img", { className: clsx('object-cover', getImageClassName({ widget, variant })), src: widget.config.url, alt: "" })) : (_jsx("div", { className: clsx(getImageClassName({ widget, variant }), 'flex items-center justify-center'), children: _jsx(ImageIcon, { size: variant === 'editor' ? 'sm' : 'lg', className: "text-muted" }) }));
    if (widget.config.destinationUrl) {
        return _jsx("a", { href: widget.config.destinationUrl, children: image });
    }
    return image;
}
function getImageClassName({ widget, variant, }) {
    const type = widget.config.type;
    if (variant === 'editor') {
        return `w-20 h-20 ${type === 'avatar' ? 'rounded-full' : 'rounded'}`;
    }
    else if (type === 'avatar') {
        return 'w-96 h-96 rounded-full mx-auto';
    }
    return 'w-full h-full rounded block';
}
