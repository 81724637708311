import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { invalidateBootstrapData, mergeBootstrapData, setBootstrapData, useBackendBootstrapData, } from './use-backend-bootstrap-data';
import { BoostrapDataContext, } from './bootstrap-data-context';
export function BootstrapDataProvider({ children }) {
    const { data } = useBackendBootstrapData();
    const value = useMemo(() => {
        return {
            data: data,
            setBootstrapData: setBootstrapData,
            mergeBootstrapData: mergeBootstrapData,
            invalidateBootstrapData: invalidateBootstrapData,
        };
    }, [data]);
    return (_jsx(BoostrapDataContext.Provider, { value: value, children: children }));
}
