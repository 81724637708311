export function urlIsValid(url) {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'https://' + url;
    }
    try {
        new URL(url);
        return true;
    }
    catch (e) {
        return false;
    }
}
