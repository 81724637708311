import {useEnableTwoFactor} from '@common/auth/ui/two-factor/requests/use-enable-two-factor';
import {TwoFactorStepperLayout} from '@common/auth/ui/two-factor/stepper/two-factor-stepper-layout';
import {Trans} from '@common/i18n/trans';
import {Button} from '@common/ui/buttons/button';
import {usePasswordConfirmedAction} from '@common/auth/ui/confirm-password/use-password-confirmed-action';

interface Props {
  onEnabled: () => void;
}
export function TwoFactorDisabledStep({onEnabled}: Props) {
  const enableTwoFactor = useEnableTwoFactor();
  const {withConfirmedPassword, isLoading: confirmPasswordIsLoading} =
    usePasswordConfirmedAction();
  const isLoading = enableTwoFactor.isPending || confirmPasswordIsLoading;

  return (
    <TwoFactorStepperLayout
      title={
        <Trans message="İki faktörlü kimlik doğrulamayı etkinleştirmediniz." />
      }
      actions={
        // <Button
        //   variant="flat"
        //   color="primary"
        //   disabled={isLoading}
        //   onClick={() => {
        //     withConfirmedPassword(() => {
        //       enableTwoFactor.mutate(undefined, {
        //         onSuccess: onEnabled,
        //       });
        //     });
        //   }}
        // >
        //   <Trans message="Enable" />
        // </Button>

<input onClick={() => {
            withConfirmedPassword(() => {
              enableTwoFactor.mutate(undefined, {
                onSuccess: onEnabled,
              });
            });
          }} className="absolute left-0 top-0 h-24 w-24 appearance-none overflow-hidden rounded outline-none ring-inset transition-shadow focus-visible:ring" type="checkbox" aria-checked="false" name="2factorcheckbox" value=""/>


      }
    />
  );
}
