import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { CustomPageBody } from '@common/custom-page/custom-page-body';
import { Footer } from '@common/ui/footer/footer';
import { ShareLinkButton } from '@app/dashboard/links/sharing/share-link-button';
import { AdHost } from '@common/admin/ads/ad-host';
import { RedirectCountdownButton } from '@app/short-links/renderers/redirect-countdown-button';
export function LinkPageRenderer({ link }) {
    const page = link.custom_page;
    const { hideNavbar, hideFooter } = page.meta || {};
    return (_jsxs("div", { className: "flex flex-col min-h-screen", children: [!hideNavbar && _jsx(LinkPageNavbar, { link: link }), _jsx(AdHost, { slot: "link_page", className: "mt-70 mb-20pa" }), _jsx("div", { className: "flex-auto", children: _jsx(CustomPageBody, { page: page }) }), !hideFooter && _jsx(Footer, { className: "mx-14 md:mx-40" })] }));
}
function LinkPageNavbar({ link }) {
    return (_jsx(Navbar, { menuPosition: "link-page-navbar", className: "flex-shrink-0 sticky top-0", rightChildren: _jsx(ShareLinkButton, { link: link }), children: _jsx(RedirectCountdownButton, { variant: "flat", color: "paper", link: link }) }));
}
