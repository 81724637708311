import {Trans} from '../../i18n/trans';
import {Button} from '../buttons/button';
import {Link} from 'react-router-dom';
import imgUrl1 from './404-1.png';
import imgUrl2 from './404-2.png';

export function NotFoundPage() {
  return (
    <div className="lg:px-96 lg:py-96 md:py-80 md:px-176 px-16 py-96 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-112 gap-64">
      <header id="not_found_header">
      <div id="not_found_div">
      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="22" viewBox="0 0 128 22" fill="none">
        <path d="M0.754883 10.2647C0.754883 4.86378 5.13317 0.485492 10.5341 0.485492H17.2698C22.6707 0.485492 27.049 4.86378 27.049 10.2647C27.049 11.4165 26.1152 12.3503 24.9634 12.3503C23.8115 12.3503 22.8778 11.4165 22.8778 10.2647C22.8778 7.16748 20.367 4.65671 17.2698 4.65671H10.5341C7.43687 4.65671 4.9261 7.16748 4.9261 10.2647C4.9261 13.3619 7.43687 15.8727 10.5341 15.8727H12.7742C13.926 15.8727 14.8598 16.8064 14.8598 17.9583C14.8598 19.1101 13.926 20.0439 12.7742 20.0439H10.5341C9.46305 20.0439 8.43225 19.8717 7.46783 19.5535L3.40927 21.2997C2.39955 21.7025 1.59509 21.6232 1.93632 20.3257L2.80873 16.2614C1.52144 14.6054 0.754883 12.5246 0.754883 10.2647Z" fill="#7B7BF7"/>
        <path d="M33.5128 19.5539C32.5487 19.8719 31.5183 20.0439 30.4478 20.0439H23.712C18.3111 20.0439 13.9328 15.6656 13.9328 10.2647C13.9328 9.11285 14.8666 8.17909 16.0185 8.17909C17.1703 8.17909 18.1041 9.11285 18.1041 10.2647C18.1041 13.3619 20.6148 15.8727 23.712 15.8727H30.4478C33.545 15.8727 36.0557 13.3619 36.0557 10.2647C36.0557 7.1675 33.545 4.65673 30.4478 4.65673H28.3722C27.2203 4.65673 26.2866 3.72297 26.2866 2.57112C26.2866 1.41927 27.2203 0.485509 28.3722 0.485509H30.4478C35.8487 0.485509 40.227 4.8638 40.227 10.2647C40.227 12.5256 39.4597 14.6073 38.1714 16.2636C36.6845 18.2271 35.6239 18.8878 33.5128 19.5539Z" fill="#7B7BF7"/>
        <path d="M43.9358 0.485492H47.61V17.0467H58.1457V20.268H43.9358V0.485492Z" fill="black"/>
        <path d="M68.3201 6.92213C67.4795 7.12675 66.5957 7.48294 65.6687 7.9907V11.2305C65.9594 10.9653 66.2815 10.7303 66.635 10.5257C66.9885 10.3135 67.3538 10.1392 67.7309 10.0028C68.1159 9.85881 68.5047 9.74892 68.8975 9.67313C69.2982 9.59735 69.6871 9.55946 70.0641 9.55946C70.779 9.55946 71.4232 9.66556 71.9967 9.87775C72.5781 10.0824 73.0691 10.3855 73.4697 10.7872C73.8782 11.1813 74.1886 11.6701 74.4007 12.2536C74.6206 12.8296 74.7306 13.4851 74.7306 14.2202V20.2679H78.2069V13.6746C78.2069 12.5151 78.0184 11.4958 77.6413 10.6167C77.272 9.72997 76.7575 8.99107 76.0976 8.39994C75.4377 7.80882 74.656 7.36548 73.7525 7.06991C72.8491 6.76677 71.8671 6.6152 70.8065 6.6152C69.9974 6.6152 69.1686 6.71751 68.3201 6.92213Z" fill="black"/>
        <path d="M79.8786 0.485474H83.2637V12.3078L89.7967 6.43438H94.7571L87.701 12.9057L95.2031 20.268H90.1254L85.1049 15.2855L83.2637 16.9737V20.268H79.8786V0.485474Z" fill="black"/>
        <path d="M98.7398 3.9683H89.9092L89.9093 0.485474H108.299L108.299 3.9683H102.698L102.698 20.268H98.7398L98.7398 3.9683Z" fill="black"/>
        <path d="M61.7614 4.69029C61.3793 4.69029 61.0319 4.64382 60.7193 4.55089C60.4153 4.45021 60.1548 4.31856 59.9377 4.15593C59.7206 3.98556 59.5512 3.79195 59.4296 3.57511C59.3167 3.35052 59.2603 3.11432 59.2603 2.8665C59.2603 2.61094 59.3167 2.37474 59.4296 2.1579C59.5512 1.93332 59.7206 1.73971 59.9377 1.57708C60.1548 1.41445 60.4153 1.28667 60.7193 1.19374C61.0319 1.09306 61.3793 1.04272 61.7614 1.04272C62.1522 1.04272 62.4996 1.09306 62.8035 1.19374C63.1162 1.28667 63.3811 1.41445 63.5982 1.57708C63.8153 1.73971 63.9803 1.93332 64.0932 2.1579C64.2148 2.37474 64.2756 2.61094 64.2756 2.8665C64.2756 3.11432 64.2148 3.35052 64.0932 3.57511C63.9803 3.79195 63.8153 3.98556 63.5982 4.15593C63.3811 4.31856 63.1162 4.45021 62.8035 4.55089C62.4996 4.64382 62.1522 4.69029 61.7614 4.69029ZM59.8335 6.56054H63.6894V20.2679H59.8335V6.56054Z" fill="black"/>
        <path d="M112.308 3.9683V0.485474H118.354C119.871 0.485474 121.187 0.645792 122.3 0.96643C123.413 1.27863 124.336 1.73427 125.07 2.33336C125.803 2.93244 126.347 3.66231 126.701 4.52297C127.064 5.38363 127.245 6.3582 127.245 7.44668C127.245 8.18077 127.157 8.88111 126.98 9.5477C126.803 10.2058 126.528 10.8134 126.157 11.3703C125.795 11.9272 125.335 12.425 124.779 12.8638C124.222 13.2941 123.569 13.6527 122.818 13.9396L127.119 20.268H122.211L118.493 14.6484H118.379L112.308 14.6357V20.268H108.299V3.9683H112.308ZM118.455 11.1678C119.214 11.1678 119.876 11.0792 120.441 10.902C121.014 10.7248 121.49 10.4759 121.87 10.1552C122.258 9.83458 122.544 9.44644 122.73 8.9908C122.924 8.52672 123.021 8.01201 123.021 7.44668C123.021 6.34132 122.641 5.4891 121.882 4.89002C121.124 4.28249 119.981 3.9683 118.455 3.9683H112.308V11.1678H118.455Z" fill="black"/>
      </svg>
      </div>

      </header>
      <div className="xl:pt-96 w-full xl:w-1/2 relative pb-48 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="relative z-10">
              <h1 className="my-8 text-main font-bold text-2xl" id='page_title_broken_page'>
                <Trans message="Aradığın sayfa bulunamadı" />
              </h1>
              
              <Button
                className="my-8"
                elementType={Link}
                size="lg"
                to="https://linkcrafter.com/"
                variant="flat"
                color="primary"
                id='page_button_broken_page'
              >
                <Trans message="Ana sayfaya dön" />
              </Button>
            </div>
          </div>
          {/* <div className="dark:opacity-5">
            <img src={imgUrl2 as any} alt="" />
          </div> */}
        </div>
      </div>
      <div className="dark:opacity-80">
        <img src={imgUrl1 as any} alt="" />
      </div>
    </div>
  );
}
