import { encode } from './util';
import SpotifyUri from './spotify-uri';
export default class Album extends SpotifyUri {
    type = 'album';
    id;
    constructor(uri, id) {
        super(uri);
        this.id = id;
    }
    static is(v) {
        return Boolean(typeof v === 'object' && v.type === 'album');
    }
    toURI() {
        return `spotify:${this.type}:${encode(this.id)}`;
    }
    toURL() {
        return `/${this.type}/${encode(this.id)}`;
    }
}
