import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
export function Skeleton({ variant = 'text', animation = 'wave', size, className, display = 'block', radius = 'rounded', style, }) {
    return (_jsx("span", { style: style, className: clsx('skeleton relative overflow-hidden bg-fg-base/4 bg-no-repeat will-change-transform', radius, skeletonSize({ variant, size }), display, variant === 'text' && 'origin-[0_55%] scale-y-[0.6]', variant === 'avatar' && 'flex-shrink-0', variant === 'icon' && 'mx-8 flex-shrink-0', animation === 'wave' && 'skeleton-wave', animation === 'pulsate' && 'skeleton-pulsate', className), "aria-busy": true, "aria-live": "polite" }));
}
function skeletonSize({ variant, size }) {
    if (size) {
        return size;
    }
    switch (variant) {
        case 'avatar':
            return 'h-40 w-40';
        case 'icon':
            return 'h-24 h-24';
        case 'rect':
            return 'h-full w-full';
        default:
            return 'w-full';
    }
}
