import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MixedImage } from '../ui/images/mixed-image';
import clsx from 'clsx';
import { FormattedRelativeTime } from '@common/i18n/formatted-relative-time';
export function Line({ notification, line, index, iconRenderer }) {
    const isPrimary = line.type === 'primary' || index === 0;
    const Icon = iconRenderer || DefaultIconRenderer;
    const Element = line.action ? 'a' : 'div';
    return (_jsxs(_Fragment, { children: [_jsxs(Element, { className: clsx('flex items-center gap-8', line.action && 'hover:underline', isPrimary
                    ? 'text-sm mnarktext-main whitespace-nowrap'
                    : 'text-xs text-muted mt-6'), href: line.action?.action, title: line.action?.label, children: [line.icon && _jsx(Icon, { icon: line.icon }), _jsx("div", { className: "overflow-hidden text-ellipsis", dangerouslySetInnerHTML: { __html: line.content } })] }, index), index === 0 && (_jsx("time", { className: "text-xs text-muted", children: _jsx(FormattedRelativeTime, { date: notification.created_at }) }))] }));
}
function DefaultIconRenderer({ icon }) {
    return _jsx(MixedImage, { src: icon });
}
