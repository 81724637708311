import { jsx as _jsx } from "react/jsx-runtime";
import { GuestRoute } from '../auth/guards/guest-route';
import { RegisterPage } from '../auth/ui/register-page';
import { useSettings } from '../core/settings/use-settings';
import { CustomPageLayout } from '@common/custom-page/custom-page-layout';
import { LoginPageWrapper } from '@common/auth/ui/login-page-wrapper';
export function DynamicHomepage({ homepageResolver }) {
    const { homepage } = useSettings();
    if (homepage?.type === 'loginPage') {
        return (_jsx(GuestRoute, { children: _jsx(LoginPageWrapper, {}) }));
    }
    if (homepage?.type === 'registerPage') {
        return (_jsx(GuestRoute, { children: _jsx(RegisterPage, {}) }));
    }
    if (homepage?.type === 'customPage') {
        return _jsx(CustomPageLayout, { slug: homepage.value });
    }
    return homepageResolver?.(homepage?.type) || null;
}
