import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m } from 'framer-motion';
import { forwardRef } from 'react';
import { useOverlayViewport } from './use-overlay-viewport';
import { Underlay } from './underlay';
import { FocusScope } from '@react-aria/focus';
import { useObjectRef } from '@react-aria/utils';
export const Tray = forwardRef(({ children, autoFocus = false, restoreFocus = true, isDismissable, isOpen, onClose, }, ref) => {
    const viewPortStyle = useOverlayViewport();
    const objRef = useObjectRef(ref);
    return (_jsxs("div", { className: "isolate z-tray fixed inset-0", style: viewPortStyle, children: [_jsx(Underlay, { onClick: () => {
                    if (isDismissable) {
                        onClose();
                    }
                } }, "tray-underlay"), _jsx(m.div, { ref: objRef, className: "absolute bottom-0 left-0 right-0 w-full z-20 rounded-t overflow-hidden max-w-375 max-h-tray mx-auto pb-safe-area", role: "presentation", initial: { opacity: 0, y: '100%' }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: '100%' }, transition: { type: 'tween', duration: 0.2 }, children: _jsx(FocusScope, { restoreFocus: restoreFocus, autoFocus: autoFocus, contain: true, children: children }) })] }));
});
