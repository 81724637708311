import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoteFavicon } from '@common/ui/remote-favicon';
import tiktokImage from './tiktok.png';
import { useEffect } from 'react';
import lazyLoader from '@common/utils/http/lazy-loader';
export function TiktokWidgetRenderer({ widget, variant, }) {
    useEffect(() => {
        lazyLoader.loadAsset('https://www.tiktok.com/embed.js', { type: 'js' });
    }, []);
    if (!widget.config.url)
        return null;
    if (variant === 'editor') {
        return (_jsxs("div", { className: "flex items-center gap-8", children: [_jsx(RemoteFavicon, { url: widget.config.url }), _jsx("a", { href: widget.config.url, target: "_blank", className: "text-muted text-sm hover:underline whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[80%]", rel: "noreferrer", children: widget.config.url })] }));
    }
    const embedURL = new URL(widget.config.url).pathname.split('/').pop()?.trim();
    return (_jsx("blockquote", { "data-video-id": embedURL, className: "tiktok-embed", children: _jsx("img", { src: tiktokImage, alt: "" }) }));
}
