import { jsx as _jsx } from "react/jsx-runtime";
import { useTrans } from '../../../i18n/use-trans';
import { message } from '../../../i18n/message';
export function DismissButton({ onDismiss }) {
    const { trans } = useTrans();
    const onClick = () => {
        if (onDismiss) {
            onDismiss();
        }
    };
    return (_jsx("button", { className: "sr-only", "aria-label": trans(message('Dismiss')), tabIndex: -1, onClick: onClick }));
}
