import { encode } from './util';
import SpotifyUri from './spotify-uri';
export default class Local extends SpotifyUri {
    type = 'local';
    artist;
    album;
    track;
    seconds;
    constructor(uri, artist, album, track, seconds) {
        super(uri);
        this.artist = artist;
        this.album = album;
        this.track = track;
        this.seconds = seconds;
    }
    static is(v) {
        return Boolean(typeof v === 'object' && v.type === 'local');
    }
    toURI() {
        return `spotify:local:${encode(this.artist)}:${encode(this.album)}:${encode(this.track)}:${this.seconds}`;
    }
    toURL() {
        return `/local/${encode(this.artist)}/${encode(this.album)}/${encode(this.track)}/${this.seconds}`;
    }
}
