import { encode } from './util';
import SpotifyUri from './spotify-uri';
export default class User extends SpotifyUri {
    type = 'user';
    user;
    constructor(uri, user) {
        super(uri);
        this.user = user;
    }
    static is(v) {
        return Boolean(typeof v === 'object' && v.type === 'user');
    }
    toURI() {
        return `spotify:${this.type}:${encode(this.user)}`;
    }
    toURL() {
        return `/${this.type}/${encode(this.user)}`;
    }
}
