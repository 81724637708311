import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Trans } from '@common/i18n/trans';
import { Button } from '@common/ui/buttons/button';
import { AnimatePresence, m } from 'framer-motion';
import { opacityAnimation } from '@common/ui/animation/opacity-animation';
import { ProgressCircle } from '@common/ui/progress/progress-circle';
export function InfiniteScrollSentinel({ query: { isInitialLoading, fetchNextPage, isFetchingNextPage, hasNextPage }, children, loaderMarginTop = 'mt-24', style, className, variant: _variant = 'infiniteScroll', loadMoreExtraContent, size = 'md', }) {
    const sentinelRef = useRef(null);
    const isLoading = isFetchingNextPage || isInitialLoading;
    const [loadMoreClickCount, setLoadMoreClickCount] = useState(0);
    const innerVariant = _variant === 'loadMore' && loadMoreClickCount < 3
        ? 'loadMore'
        : 'infiniteScroll';
    useEffect(() => {
        const sentinelEl = sentinelRef.current;
        if (!sentinelEl || innerVariant === 'loadMore')
            return;
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting && hasNextPage && !isLoading) {
                fetchNextPage();
            }
        });
        observer.observe(sentinelEl);
        return () => {
            observer.unobserve(sentinelEl);
        };
    }, [fetchNextPage, hasNextPage, isLoading, innerVariant]);
    let content;
    if (children) {
        // children might already be wrapped in AnimatePresence, so only wrap default loader with it
        content = isFetchingNextPage ? children : null;
    }
    else if (innerVariant === 'loadMore') {
        content = !isInitialLoading && hasNextPage && (_jsxs("div", { className: clsx('flex items-center gap-8', loaderMarginTop), children: [loadMoreExtraContent, _jsx(Button, { size: size === 'md' ? 'sm' : 'xs', className: clsx(size === 'sm' ? 'min-h-24 min-w-96' : 'min-h-36 min-w-112'), variant: "outline", color: "primary", onClick: () => {
                        fetchNextPage();
                        setLoadMoreClickCount(loadMoreClickCount + 1);
                    }, disabled: isLoading, children: loadMoreClickCount >= 2 && !isFetchingNextPage ? (_jsx(Trans, { message: "Load all" })) : (_jsx(Trans, { message: "Show more" })) })] }));
    }
    else {
        content = (_jsx(AnimatePresence, { children: isFetchingNextPage && (_jsx(m.div, { className: clsx('flex w-full justify-center', loaderMarginTop), ...opacityAnimation, children: _jsx(ProgressCircle, { size: size, isIndeterminate: true, "aria-label": "loading" }) })) }));
    }
    return (_jsxs("div", { style: style, className: clsx('w-full', className, hasNextPage && 'min-h-36'), role: "presentation", children: [_jsx("div", { ref: sentinelRef, "aria-hidden": true }), content] }));
}
