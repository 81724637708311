import { jsx as _jsx } from "react/jsx-runtime";
import getVideoId from 'get-video-id';
import { VideoEmbedWidgetRenderer } from '@app/dashboard/biolink/biolink-editor/content/widgets/video-embed-widget-renderer';
export function VimeoWidgetRenderer({ widget, variant, }) {
    if (!widget.config.url)
        return null;
    const { id } = getVideoId(widget.config.url);
    const embedUrl = `https://player.vimeo.com/video/${id}`;
    return _jsx(VideoEmbedWidgetRenderer, { variant: variant, embedUrl: embedUrl });
}
