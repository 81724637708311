import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import secureFilesSvg from '@common/auth/ui/account-settings/access-token-panel/secure-files.svg';
import { useTrans } from '@common/i18n/use-trans';
import { Button } from '@common/ui/buttons/button';
import { FormTextField } from '@common/ui/forms/input-field/text-field/text-field';
import { SvgImage } from '@common/ui/images/svg-image/svg-image';
import { Trans } from '@common/i18n/trans';
import { useCheckLinkPassword, } from '@app/short-links/requests/use-check-link-password';
import { useForm } from 'react-hook-form';
import { Form } from '@common/ui/forms/form';
export function PasswordPage({ linkeable, onPasswordValid }) {
    const { trans } = useTrans();
    const fieldLabel = trans({ message: 'Password' });
    const form = useForm();
    const checkPassword = useCheckLinkPassword(linkeable, form);
    return (_jsx("div", { className: "flex h-screen w-full items-center justify-center bg-alt", children: _jsxs("div", { className: "m-14 flex max-w-[560px] flex-col items-center gap-40 rounded border bg p-24 md:flex-row md:gap-14", children: [_jsx("div", { className: "h-132 w-[165px]", children: _jsx(SvgImage, { src: secureFilesSvg }) }), _jsxs(Form, { form: form, onSubmit: values => {
                        checkPassword.mutate(values, { onSuccess: onPasswordValid });
                    }, children: [_jsx("span", { className: "text-sm", children: linkeable.model_type === 'biolink' ? (_jsx(Trans, { message: "The biolink you are trying to access is password protected." })) : (_jsx(Trans, { message: "The link you are trying to access is password protected." })) }), _jsx(FormTextField, { name: "password", autoFocus: true, placeholder: fieldLabel, "aria-label": fieldLabel, className: "mb-20 mt-10", type: "password", required: true }), _jsx("div", { className: "text-right", children: _jsx(Button, { variant: "flat", color: "primary", type: "submit", className: "w-full md:w-auto", disabled: checkPassword.isPending, children: _jsx(Trans, { message: "Enter" }) }) })] })] }) }));
}
