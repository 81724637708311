import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
export function IllustratedMessage({ image, title, description, action, className, size = 'md', imageHeight, imageMargin = 'mb-24', }) {
    const style = getSizeClassName(size, imageHeight);
    return (_jsxs("div", { className: clsx('text-center', className), children: [image && _jsx("div", { className: clsx(style.image, imageMargin), children: image }), title && (_jsx("div", { className: clsx(style.title, 'mb-2 text-main'), children: title })), description && (_jsx("div", { className: clsx(style.description, 'text-muted'), children: description })), action && _jsx("div", { className: "mt-20", children: action })] }));
}
function getSizeClassName(size, imageHeight) {
    switch (size) {
        case 'xs':
            return {
                image: imageHeight || 'h-60',
                title: 'text-sm',
                description: 'text-xs',
            };
        case 'sm':
            return {
                image: imageHeight || 'h-80',
                title: 'text-base',
                description: 'text-sm',
            };
        default:
            return {
                image: imageHeight || 'h-128',
                title: 'text-lg',
                description: 'text-base',
            };
    }
}
