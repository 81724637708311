import { useCallback, useContext } from 'react';
import { SiteConfigContext } from '../core/settings/site-config-context';
import { getFromLocalStorage } from '../utils/hooks/local-storage';
import { useBootstrapData } from '../core/bootstrap-data/bootstrap-data-context';
export function useAuth() {
    const { data: { user, guest_role }, } = useBootstrapData();
    const { auth: { redirectUri = '/' }, } = useContext(SiteConfigContext);
    const getPermission = useCallback((name) => {
        const permissions = user?.permissions || guest_role?.permissions;
        if (!permissions)
            return;
        return permissions.find(p => p.name === name);
    }, [user?.permissions, guest_role?.permissions]);
    const getRestrictionValue = useCallback((permissionName, restrictionName) => {
        const permission = getPermission(permissionName);
        let restrictionValue = null;
        if (permission) {
            const restriction = permission.restrictions.find(r => r.name === restrictionName);
            restrictionValue = restriction ? restriction.value : undefined;
        }
        return restrictionValue;
    }, [getPermission]);
    const hasPermission = useCallback((name) => {
        const permissions = user?.permissions || guest_role?.permissions;
        const isAdmin = permissions?.find(p => p.name === 'admin') != null;
        return isAdmin || getPermission(name) != null;
    }, [user?.permissions, guest_role?.permissions, getPermission]);
    const checkOverQuotaOrNoPermission = useCallback((permission, restrictionName, currentCount) => {
        const noPermission = !hasPermission(permission);
        const maxCount = getRestrictionValue(permission, restrictionName);
        const overQuota = maxCount != null && currentCount >= maxCount;
        return {
            overQuota: maxCount != null && currentCount >= maxCount,
            noPermission,
            overQuotaOrNoPermission: overQuota || noPermission,
        };
    }, [getRestrictionValue, hasPermission]);
    const isSubscribed = user?.subscriptions?.find(sub => sub.valid) != null;
    const getRedirectUri = useCallback(() => {
        const onboarding = getFromLocalStorage('be.onboarding.selected');
        if (onboarding) {
            return `/checkout/${onboarding.productId}/${onboarding.priceId}`;
        }
        return redirectUri;
    }, [redirectUri]);
    const hasRole = useCallback((roleId) => {
        return user?.roles?.find(role => role.id === roleId) != null;
    }, [user]);
    return {
        user,
        hasPermission,
        getPermission,
        getRestrictionValue,
        checkOverQuotaOrNoPermission,
        isLoggedIn: !!user,
        isSubscribed,
        hasRole,
        // where to redirect user after successful login
        getRedirectUri,
    };
}
