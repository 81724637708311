import parse from './parse';
import Search from './search';
import Local from './local';
import Playlist from './playlist';
import Artist from './artist';
import Album from './album';
import Track from './track';
import User from './user';
import Episode from './episode';
export { parse, Search, Episode, Local, Playlist, Track, Artist, Album, User };
export function formatURI(input) {
    const uri = typeof input === 'string' ? parse(input) : input;
    return uri.toURI();
}
export function formatEmbedURL(input) {
    const uri = typeof input === 'string' ? parse(input) : input;
    return uri.toEmbedURL();
}
export function formatOpenURL(input) {
    const uri = typeof input === 'string' ? parse(input) : input;
    return uri.toOpenURL();
}
export function formatPlayURL(input) {
    const uri = typeof input === 'string' ? parse(input) : input;
    return uri.toPlayURL();
}
