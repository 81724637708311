export function useListboxKeyboardNavigation({ state: { isOpen, setIsOpen, selectedIndex, activeIndex, setInputValue, setActiveIndex, }, focusLoopingMode, collection, focusItem, handleItemSelection, allowCustomValue, }) {
    const handleTriggerKeyDown = (e) => {
        // ignore if dropdown is open or if event bubbled up from portal
        if (isOpen || !e.currentTarget.contains(e.target))
            return;
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setIsOpen(true);
            focusItem('increment', selectedIndex != null ? selectedIndex : 0);
            return true;
        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setIsOpen(true);
            focusItem('decrement', selectedIndex != null ? selectedIndex : collection.size - 1);
            return true;
        }
        else if (e.key === 'Enter' || e.key === 'Space') {
            e.preventDefault();
            setIsOpen(true);
            focusItem('increment', selectedIndex != null ? selectedIndex : 0);
            return true;
        }
    };
    const handleListboxKeyboardNavigation = (e) => {
        const lastIndex = Math.max(0, collection.size - 1);
        // ignore if event bubbled up from portal, or dropdown is closed
        if (!isOpen || !e.currentTarget.contains(e.target))
            return;
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                if (activeIndex == null) {
                    focusItem('increment', 0);
                }
                else if (activeIndex >= lastIndex) {
                    // if focus is not looping, stay on last index
                    if (focusLoopingMode === 'loop') {
                        focusItem('increment', 0);
                    }
                    else if (focusLoopingMode === 'deselect') {
                        setActiveIndex(null);
                    }
                }
                else {
                    focusItem('increment', activeIndex + 1);
                }
                return true;
            case 'ArrowUp':
                e.preventDefault();
                if (activeIndex == null) {
                    focusItem('decrement', lastIndex);
                }
                else if (activeIndex <= 0) {
                    // if focus is not looping, stay on first index
                    if (focusLoopingMode === 'loop') {
                        focusItem('decrement', lastIndex);
                    }
                    else if (focusLoopingMode === 'deselect') {
                        setActiveIndex(null);
                    }
                }
                else {
                    focusItem('decrement', activeIndex - 1);
                }
                return true;
            case 'Home':
                e.preventDefault();
                focusItem('increment', 0);
                return true;
            case 'End':
                e.preventDefault();
                focusItem('decrement', lastIndex);
                return true;
            case 'Tab':
                setIsOpen(false);
                return true;
        }
    };
    const handleListboxSearchFieldKeydown = (e) => {
        if (e.key === 'Enter' && activeIndex != null && collection.size) {
            // prevent form submit when selecting item in combobox via "enter"
            e.preventDefault();
            const [value, obj] = [...collection.entries()][activeIndex];
            if (value) {
                handleItemSelection(value);
                // "onSelected" will not be called for dropdown items, because keydown
                // event will never be triggered for them in "virtualFocus" mode
                obj.element.props.onSelected?.();
            }
            return;
        }
        // on escape, clear input and close dropdown
        if (e.key === 'Escape' && isOpen) {
            setIsOpen(false);
            if (!allowCustomValue) {
                setInputValue('');
            }
        }
        const handled = handleTriggerKeyDown(e);
        if (!handled) {
            handleListboxKeyboardNavigation(e);
        }
    };
    return {
        handleTriggerKeyDown,
        handleListboxKeyboardNavigation,
        handleListboxSearchFieldKeydown,
    };
}
