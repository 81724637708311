import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useDeleteAccount} from './delete-account';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import React, {useState} from 'react';
import {usePasswordConfirmedAction} from '@common/auth/ui/confirm-password/use-password-confirmed-action';

export function DangerZonePanel() {
  const deleteAccount = useDeleteAccount();
  const {withConfirmedPassword, isLoading: confirmingPassword} =
    usePasswordConfirmedAction();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.DeleteAccount}
      title={<Trans message="Danger zone" />}
    >
      <DialogTrigger
        type="modal"
        isOpen={confirmDialogOpen}
        onOpenChange={setConfirmDialogOpen}
        onClose={isConfirmed => {
          if (isConfirmed) {
            deleteAccount.mutate();
          }
        }}
      >
        <ConfirmationDialog
          isDanger
          title={<Trans message="Hesabı sil?" />}
          body={
            <Trans message="Hesabınız kalıcı olarak silinecektir. Hesaplar silindikten sonra geri getirilemez!" />
          }
          confirm={<Trans message="Sil" />}
        />
      </DialogTrigger>
      {/* <Button
        variant="flat"
        color="danger"
        disabled={confirmingPassword || deleteAccount.isPending}
        onClick={() => {
          withConfirmedPassword(() => {
            setConfirmDialogOpen(true);
          });
        }}
      >
        <Trans message="Delete account" />
      </Button> */}

      <div className='accountframe'>
          <div className='accountframeInside'>
              <div className='accountframeInsideTitle'>Hesabı sil</div>
              <p className='acccountframeParag'>Bu hesabı kalıcı olarak silmek için bu seçeneği seç. Hesap silindiğinde kullanılan tüm sayfalar da kalıcı olarak silinecektir.</p>
          </div>
        </div><br></br>
        <button className='acccountframeDeleteAccountButton' type='button' onClick={() => {
          withConfirmedPassword(() => {
            setConfirmDialogOpen(true);
          });
        }}>Hesabı sil</button>


    </AccountSettingsPanel>
  );
}
