import { jsx as _jsx } from "react/jsx-runtime";
import { removeProtocol } from '@common/utils/urls/remove-protocol';
import { useSettings } from '@common/core/settings/use-settings';
import { VideoEmbedWidgetRenderer } from '@app/dashboard/biolink/biolink-editor/content/widgets/video-embed-widget-renderer';
export function TwitchWidgetRenderer({ widget, variant, }) {
    const { base_url } = useSettings();
    if (!widget.config.url)
        return null;
    const embedUrl = getTwitchEmbedUrl(widget.config.url, base_url);
    return _jsx(VideoEmbedWidgetRenderer, { variant: variant, embedUrl: embedUrl });
}
function getTwitchEmbedUrl(twitchUrl, siteUrl) {
    siteUrl = removeProtocol(siteUrl);
    let embedUrl;
    const channelOrClipId = new URL(twitchUrl).pathname.split('/').pop()?.trim();
    if (twitchUrl.includes('clip')) {
        embedUrl = `https://clips.twitch.tv/embed?clip=${channelOrClipId}`;
    }
    else {
        embedUrl = `https://player.twitch.tv/?channel=${channelOrClipId}`;
    }
    return `${embedUrl}&parent=${siteUrl}`;
}
