import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuTrigger } from '@common/ui/navigation/menu/menu-trigger';
import { ShareIcon } from '@common/icons/material/Share';
import { Item } from '@common/ui/forms/listbox/item';
import { Trans } from '@common/i18n/trans';
import { CopyLinkIcon } from '@app/dashboard/links/sharing/copy-link-icon';
import { QrCode2Icon } from '@common/icons/material/QrCode2';
import { FacebookIcon } from '@common/icons/social/facebook';
import { TwitterIcon } from '@common/icons/social/twitter';
import { useSettings } from '@common/core/settings/use-settings';
import useClipboard from 'react-use-clipboard';
import { toast } from '@common/ui/toast/toast';
import { message } from '@common/i18n/message';
import { shareLinkSocially } from '@common/utils/urls/share-link-socially';
import { useTrans } from '@common/i18n/use-trans';
import { Tooltip } from '@common/ui/tooltip/tooltip';
import { Button } from '@common/ui/buttons/button';
import { IconButton } from '@common/ui/buttons/icon-button';
export function ShareLinkButton({ link, className, variant = 'icon', }) {
    const { base_url } = useSettings();
    const { trans } = useTrans();
    let url;
    if ('short_url' in link && link.short_url) {
        url = link.short_url;
    }
    else {
        url = `${base_url}/${link.hash}`;
    }
    const [, setUrlCopied] = useClipboard(url);
    const [, setQrCopied] = useClipboard(`${url}/qr`);
    const trigger = variant === 'text' ? (_jsx(Button, { className: className, startIcon: _jsx(ShareIcon, {}), variant: "text", children: _jsx(Trans, { message: "Share" }) })) : (_jsx(IconButton, { className: className, children: _jsx(ShareIcon, {}) }));
    return (_jsxs(MenuTrigger, { children: [_jsx(Tooltip, { label: _jsx(Trans, { message: "Share" }), children: trigger }), _jsxs(Menu, { children: [_jsx(Item, { value: "clipboard", startIcon: _jsx(CopyLinkIcon, {}), onSelected: () => {
                            setUrlCopied();
                            toast.positive(message('Copied link to clipboard'));
                        }, children: _jsx(Trans, { message: "Copy to clipboard" }) }), _jsx(Item, { value: "qr", startIcon: _jsx(QrCode2Icon, {}), onClick: () => {
                            setQrCopied();
                            toast.positive(message('Copied QR code link to clipboard'));
                        }, children: _jsx(Trans, { message: "Copy QR code" }) }), _jsx(Item, { value: "facebook", startIcon: _jsx(FacebookIcon, {}), onClick: () => {
                            shareLinkSocially('facebook', url, trans(message('Check out this link')));
                        }, children: _jsx(Trans, { message: "Share to facebook" }) }), _jsx(Item, { value: "twitter", startIcon: _jsx(TwitterIcon, {}), onClick: () => {
                            shareLinkSocially('twitter', url, trans(message('Check out this link')));
                        }, children: _jsx(Trans, { message: "Share to twitter" }) })] })] }));
}
