import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { Trans } from '@common/i18n/trans';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { Chip } from '@common/ui/forms/input-field/chip-field/chip';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { ErrorIcon } from '@common/icons/material/Error';
import { highlightCode } from '@common/text-editor/highlight/highlight-code';
import { IgnitionFilePath, IgnitionStackTrace, } from '@common/utils/http/ignition-error-dialog/ignition-stack-trace';
import { Button } from '@common/ui/buttons/button';
export function IgnitionErrorDialog({ error }) {
    const [selectedIndex, setSelectedIndex] = useState(() => {
        for (const frame of error.trace) {
            if (!('vendorGroup' in frame)) {
                return frame.flatIndex;
            }
        }
        return 0;
    });
    const selectedFrame = useMemo(() => {
        for (const frame of error.trace) {
            if ('vendorGroup' in frame) {
                for (const vendorFrame of frame.items) {
                    if (vendorFrame.flatIndex === selectedIndex) {
                        return vendorFrame;
                    }
                }
            }
            else if (frame.flatIndex === selectedIndex) {
                return frame;
            }
        }
    }, [error, selectedIndex]);
    return (_jsxs(Dialog, { size: "fullscreen", children: [_jsx(DialogHeader, { showDivider: true, leftAdornment: _jsx(ErrorIcon, {}), color: "text-danger", actions: _jsx(DownloadButton, {}), children: _jsx(Trans, { message: "An error occured" }) }), _jsxs(DialogBody, { padding: "p-0 stack", children: [_jsxs("div", { className: "sticky top-0 z-10 border-b bg p-24", children: [_jsx(Chip, { className: "w-max", radius: "rounded-panel", children: error.exception }), _jsx("div", { className: "mt-16 line-clamp-2 text-lg font-semibold leading-snug", children: error.message })] }), _jsxs("div", { className: "flex items-stretch gap-10", children: [_jsx(IgnitionStackTrace, { trace: error.trace, onSelectedIndexChange: setSelectedIndex, selectedIndex: selectedIndex, totalVendorGroups: error.totalVendorGroups }), selectedFrame && _jsx(CodeSnippet, { frame: selectedFrame })] })] })] }));
}
function CodeSnippet({ frame }) {
    const codeRef = useRef(null);
    const lineNumbers = Object.keys(frame.codeSnippet).map(Number);
    const highlightedIndex = lineNumbers.indexOf(frame.lineNumber);
    const lines = Object.values(frame.codeSnippet);
    return (_jsxs("div", { className: "sticky top-120 flex-auto", children: [_jsx("div", { className: "px-30 py-16 text-right text-muted", children: _jsx(IgnitionFilePath, { frame: frame }) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "mr-8 select-none text-right", children: lineNumbers.map((lineNumber, index) => (_jsx("div", { className: clsx('px-8 font-mono leading-loose text-muted', index == highlightedIndex && 'bg-danger/30'), children: lineNumber }, index))) }), _jsx("div", { className: "compact-scrollbar flex-grow overflow-x-auto", children: _jsx("pre", { children: _jsx("code", { className: "language-php", ref: codeRef, children: lines.map((line, index) => (_jsx(CodeSnippetLine, { isHighlighted: highlightedIndex === index, line: line }, `${frame.path}.${index}`))) }) }) })] })] }));
}
const CodeSnippetLine = memo(({ line, isHighlighted }) => {
    const ref = useRef(null);
    useEffect(() => {
        const el = ref.current;
        highlightCode(el, 'light');
        return () => {
            delete el.dataset.highlighted;
        };
    }, []);
    return (_jsx("span", { className: clsx('block leading-loose', isHighlighted && 'bg-danger/20'), children: _jsx("span", { className: "language-php", ref: ref, children: line + '\n' }) }));
});
function DownloadButton() {
    return (_jsx(Button, { variant: "outline", className: "text-main", elementType: "a", download: true, href: "api/v1/logs/error/download-latest", size: "2xs", children: _jsx(Trans, { message: "Download log" }) }));
}
