import { useMutation } from '@tanstack/react-query';
import { apiClient } from '@common/http/query-client';
import { onFormQueryError } from '@common/errors/on-form-query-error';
export function useCheckLinkPassword(linkeable, form) {
    return useMutation({
        mutationFn: (payload) => checkPassword(linkeable, payload),
        onError: err => onFormQueryError(err, form),
    });
}
function checkPassword(linkeable, payload) {
    return apiClient
        .post('links/check-password', {
        ...payload,
        linkeableType: linkeable.model_type,
        linkeableId: linkeable.id,
    })
        .then(r => r.data);
}
