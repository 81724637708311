import { jsx as _jsx } from "react/jsx-runtime";
import { FullPageLoader } from '@common/ui/progress/full-page-loader';
import { errorStatusIs } from '@common/utils/http/error-status-is';
import { NotFoundPage } from '@common/ui/not-found-page/not-found-page';
import { PageErrorMessage } from '@common/errors/page-error-message';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@common/auth/use-auth';
import useSpinDelay from '@common/utils/hooks/use-spin-delay';
export function PageStatus({ query, show404 = true, loader, loaderClassName, loaderIsScreen = true, delayedSpinner = true, redirectOn404, }) {
    const { isLoggedIn } = useAuth();
    const showSpinner = useSpinDelay(query.isLoading, {
        delay: 500,
        minDuration: 200,
    });
    if (query.isLoading) {
        if (!showSpinner && delayedSpinner) {
            return null;
        }
        return (loader || (_jsx(FullPageLoader, { className: loaderClassName, screen: loaderIsScreen })));
    }
    if (query.isError &&
        (errorStatusIs(query.error, 401) || errorStatusIs(query.error, 403)) &&
        !isLoggedIn) {
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    if (show404 && query.isError && errorStatusIs(query.error, 404)) {
        if (redirectOn404) {
            return _jsx(Navigate, { to: redirectOn404, replace: true });
        }
        return _jsx(NotFoundPage, {});
    }
    return _jsx(PageErrorMessage, {});
}
