export class ToastTimer {
    callback;
    remaining;
    timerId;
    createdAt = 0;
    constructor(callback, remaining) {
        this.callback = callback;
        this.remaining = remaining;
        this.resume();
    }
    pause() {
        clearTimeout(this.timerId);
        this.remaining -= Date.now() - this.createdAt;
    }
    resume() {
        this.createdAt = Date.now();
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(this.callback, this.remaining);
    }
    clear() {
        clearTimeout(this.timerId);
    }
}
