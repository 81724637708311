import { jsx as _jsx } from "react/jsx-runtime";
import { FormProvider, } from 'react-hook-form';
export function Form({ children, onBeforeSubmit, onSubmit, form, className, id, onBlur, }) {
    return (_jsx(FormProvider, { ...form, children: _jsx("form", { id: id, onBlur: onBlur, className: className, onSubmit: e => {
                // prevent parent forms from submitting, if nested
                e.stopPropagation();
                onBeforeSubmit?.();
                form.handleSubmit(onSubmit)(e);
            }, children: children }) }));
}
