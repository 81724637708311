import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { RemoteFavicon } from '@common/ui/remote-favicon';
export function VideoEmbedWidgetRenderer({ variant, embedUrl, }) {
    if (!embedUrl)
        return null;
    if (variant === 'editor') {
        return (_jsxs("div", { className: "flex items-center gap-8", children: [_jsx(RemoteFavicon, { url: embedUrl }), _jsx("a", { href: embedUrl, target: "_blank", className: "text-muted text-sm hover:underline", rel: "noreferrer", children: embedUrl })] }));
    }
    return (_jsx("iframe", { className: clsx('aspect-video w-full rounded shadow-lg'), loading: "lazy", src: embedUrl, allow: "autoplay; encrypted-media; picture-in-picture", allowFullScreen: true }));
}
