import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '../../buttons/button';
import { ErrorOutlineIcon } from '@common/icons/material/ErrorOutline';
import { DialogFooter } from './dialog-footer';
import { useDialogContext } from './dialog-context';
import { Dialog } from './dialog';
import { DialogHeader } from './dialog-header';
import { DialogBody } from './dialog-body';
import { Trans } from '@common/i18n/trans';
export function ConfirmationDialog({ className, title, body, confirm, isDanger, isLoading, onConfirm, }) {
    const { close } = useDialogContext();
    return (_jsxs(Dialog, { className: className, size: "sm", role: "alertdialog", children: [_jsx(DialogHeader, { color: isDanger ? 'text-danger' : null, leftAdornment: _jsx(ErrorOutlineIcon, { className: "icon-sm" }), children: title }), _jsx(DialogBody, { children: body }), _jsxs(DialogFooter, { children: [_jsx(Button, { variant: "text", onClick: () => {
                            close(false);
                        }, children: _jsx(Trans, { message: "Cancel" }) }), _jsx(Button, { disabled: isLoading, variant: "flat", color: isDanger ? 'danger' : 'primary', onClick: () => {
                            onConfirm?.();
                            // if callback is passed in, caller is responsible for closing the dialog
                            if (!onConfirm) {
                                close(true);
                            }
                        }, children: confirm })] })] }));
}
