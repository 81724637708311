import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {useUser} from '../use-user';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {SocialLoginPanel} from './social-login-panel';
import {BasicInfoPanel} from './basic-info-panel/basic-info-panel';
import {ChangePasswordPanel} from './change-password-panel/change-password-panel';
import {LocalizationPanel} from './localization-panel';
import {AccessTokenPanel} from './access-token-panel/access-token-panel';
import {DangerZonePanel} from './danger-zone-panel/danger-zone-panel';
import {Trans} from '@common/i18n/trans';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {AccountSettingsPanel} from '@common/auth/ui/account-settings/account-settings-panel';
import {TwoFactorStepper} from '@common/auth/ui/two-factor/stepper/two-factor-auth-stepper';
import {
  AccountSettingsId,
  AccountSettingsSidenav,
} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {SessionsPanel} from '@common/auth/ui/account-settings/sessions-panel/sessions-panel';
import {useContext} from 'react';
import {SiteConfigContext} from '@common/core/settings/site-config-context';

export function AccountSettingsPage() {
  const {auth} = useContext(SiteConfigContext);
  const {data, isLoading} = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });

 

  setTimeout(() => {
    if (window.location.href.indexOf('account-settings') > -1) {
      // Current URL contains 'account-settings'
      // Do something here, e.g., show a specific element, trigger a function, etc.
      console.log(data?.user.email);
      console.log(data?.user.first_name);
      console.log(data?.user.email_verified_at);
      
      const accountFrameName = document.getElementById('accountframeInsideFirstName');
      const accountFrameEmail = document.getElementById('accountframeInsideEmail');
      const email_verify_area = document.getElementById('email_verify_area');
      const pop_up_input_field_area = document.getElementById('pop_up_input_field_area');
      if(accountFrameName != undefined && data?.user.first_name != undefined) {
        accountFrameName.innerHTML = data?.user.first_name;
        console.log("/me api worker name");
      }

      if(accountFrameEmail != undefined && data?.user.email != undefined && pop_up_input_field_area != undefined) {
        accountFrameEmail.innerHTML = data?.user.email;
        pop_up_input_field_area.innerHTML = data?.user.email;
        console.log("/me api worker email");
      }

      if(email_verify_area != undefined) {
        if(data?.user.email_verified_at == null) {
          //Email Onaysız
          console.log('Email Onaysız');
          email_verify_area.innerHTML = '<span class=\'accountEmailNotVerifyButton\'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><g clip-path="url(#clip0_320_6896)"> <path d="M11.8538 0.646759C11.76 0.553024 11.6328 0.500366 11.5003 0.500366C11.3677 0.500366 11.2405 0.553024 11.1468 0.646759L6.00026 5.79326L0.853759 0.646759C0.759996 0.553024 0.632842 0.500366 0.500259 0.500366C0.367677 0.500366 0.240523 0.553024 0.146759 0.646759C0.0530239 0.740523 0.000366211 0.867677 0.000366211 1.00026C0.000366211 1.13284 0.0530239 1.26 0.146759 1.35376L5.29326 6.50026L0.146759 11.6468C0.0530239 11.7405 0.000366211 11.8677 0.000366211 12.0003C0.000366211 12.1328 0.0530239 12.26 0.146759 12.3538C0.240523 12.4475 0.367677 12.5002 0.500259 12.5002C0.632842 12.5002 0.759996 12.4475 0.853759 12.3538L6.00026 7.20726L11.1468 12.3538C11.2405 12.4475 11.3677 12.5002 11.5003 12.5002C11.6328 12.5002 11.76 12.4475 11.8538 12.3538C11.9475 12.26 12.0002 12.1328 12.0002 12.0003C12.0002 11.8677 11.9475 11.7405 11.8538 11.6468L6.70726 6.50026L11.8538 1.35376C11.9475 1.26 12.0002 1.13284 12.0002 1.00026C12.0002 0.867677 11.9475 0.740523 11.8538 0.646759Z" fill="white"/> </g> <defs>  <clipPath id="clip0_320_6896"> <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/> </clipPath> </defs></svg> Doğrulanmadı</span>';
        }
        else {
          //Email Onaylı
          console.log('Email Onaylı');
          email_verify_area.innerHTML = '<span class=\'accountEmailVerifyButton\'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">  <g clip-path="url(#clip0_268_447)">  <path d="M11.1594 2.7155L4.24994 9.6245C4.20348 9.67114 4.14828 9.70815 4.08749 9.7334C4.0267 9.75865 3.96152 9.77165 3.89569 9.77165C3.82986 9.77165 3.76468 9.75865 3.70389 9.7334C3.6431 9.70815 3.58789 9.67114 3.54144 9.6245L0.869439 6.95C0.822984 6.90337 0.767777 6.86636 0.706986 6.84111C0.646195 6.81586 0.581016 6.80286 0.515189 6.80286C0.449362 6.80286 0.384183 6.81586 0.323391 6.84111C0.2626 6.86636 0.207393 6.90337 0.160939 6.95C0.1143 6.99646 0.0772939 7.05167 0.0520431 7.11246C0.0267923 7.17325 0.0137939 7.23843 0.0137939 7.30425C0.0137939 7.37008 0.0267923 7.43526 0.0520431 7.49605C0.0772939 7.55684 0.1143 7.61205 0.160939 7.6585L2.83394 10.331C3.11591 10.6125 3.49804 10.7705 3.89644 10.7705C4.29484 10.7705 4.67697 10.6125 4.95894 10.331L11.8679 3.4235C11.9145 3.37706 11.9514 3.32188 11.9767 3.26114C12.0019 3.20039 12.0148 3.13527 12.0148 3.0695C12.0148 3.00374 12.0019 2.93862 11.9767 2.87787C11.9514 2.81713 11.9145 2.76195 11.8679 2.7155C11.8215 2.66887 11.7663 2.63186 11.7055 2.60661C11.6447 2.58136 11.5795 2.56836 11.5137 2.56836C11.4479 2.56836 11.3827 2.58136 11.3219 2.60661C11.2611 2.63186 11.2059 2.66887 11.1594 2.7155Z" fill="white"/> </g> <defs> <clipPath id="clip0_268_447"> <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/> </clipPath> </defs></svg> Doğrulandı</span>';
        }
        
        console.log("/me api worker email checker");
      }

    }
  }, 100);
  

  return (
    <div className="min-h-screen bg-alt">
      <StaticPageTitle>
        <Trans message="Profil" />
      </StaticPageTitle>
      <Navbar menuPosition="account-settings-page" />
      <div>
        <div className="container mx-auto px-24 py-24">
          <h1 className="text-3xl">
            <Trans message="Profil" />
          </h1>
          {/* <div className="mb-40 text-base text-muted">
            <Trans message="View and update your account details, profile and more." />
          </div> */}
          {isLoading || !data ? (
            <ProgressCircle
              className="my-80"
              aria-label="Loading user.."
              isIndeterminate
            />
          ) : (
            <div className="flex items-start gap-24">
              {/* <AccountSettingsSidenav /> */}
              <main className="flex-auto">
                {auth.accountSettingsPanels?.map(panel => (
                  <panel.component key={panel.id} user={data.user} />
                ))}
                <BasicInfoPanel user={data.user} />
                <SocialLoginPanel user={data.user} />
                <ChangePasswordPanel />
                <AccountSettingsPanel
                  id={AccountSettingsId.TwoFactor}
                  title={<Trans message="Two factor authentication" />}
                >
                    <TwoFactorStepper user={data.user} />

                </AccountSettingsPanel>
                {/* <SessionsPanel user={data.user} />
                <LocalizationPanel user={data.user} />
                <AccessTokenPanel user={data.user} /> */}
                <DangerZonePanel />
              </main>
            </div>
          )}
        </div>
      </div>
    </div>

  );
}
