/**
 * URL-decode, also replaces `+` (plus) chars with ` ` (space).
 *
 * @param {String} str
 * @api private
 */
export function decode(str) {
    return decodeURIComponent(str).replace(/\+/g, ' ');
}
/**
 * URL-encode, also turn ` ` (space) chars into `+` (plus).
 *
 * @param {String} str
 * @api private
 */
export function encode(str) {
    return escape(str.replace(/ /g, '+'));
}
