import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { RemoteFavicon } from '@common/ui/remote-favicon';
import { formatEmbedURL } from '@app/dashboard/biolink/biolink-editor/content/widgets/spotify-widget/spotify-uri';
export function SpotifyWidgetRenderer({ widget, variant, }) {
    if (!widget.config.url)
        return null;
    if (variant === 'editor') {
        return (_jsxs("div", { className: "flex items-center gap-8", children: [_jsx(RemoteFavicon, { url: widget.config.url }), _jsx("a", { href: widget.config.url, target: "_blank", className: "text-muted text-sm hover:underline whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[80%]", rel: "noreferrer", children: widget.config.url })] }));
    }
    const embedURL = formatEmbedURL(widget.config.url);
    return (_jsx("iframe", { className: clsx('w-full rounded shadow-lg', getEmbedHeight(widget.config.type)), loading: "lazy", src: embedURL, allow: "autoplay; encrypted-media; picture-in-picture", allowFullScreen: true }));
}
function getEmbedHeight(type) {
    switch (type) {
        case 'track':
            return 'h-80';
        default:
            return 'h-[152px]';
    }
}
