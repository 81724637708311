import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../use-auth';
import { NotFoundPage } from '@common/ui/not-found-page/not-found-page';
import { useSettings } from '@common/core/settings/use-settings';
export function AuthRoute({ children, permission, requireLogin = true }) {
    const { isLoggedIn, hasPermission, isSubscribed } = useAuth();
    const { billing } = useSettings();
    if ((requireLogin && !isLoggedIn) ||
        (permission && !hasPermission(permission))) {
        if (isLoggedIn) {
            return billing.enable && !isSubscribed ? (_jsx(Navigate, { to: "/pricing", replace: true })) : (_jsx(NotFoundPage, {}));
        }
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    return children || _jsx(Outlet, {});
}
