import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from './trans';
import { Fragment } from 'react';
export function MixedText({ value }) {
    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        return _jsx(Fragment, { children: value });
    }
    return _jsx(Trans, { ...value });
}
