import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Adornment } from './adornment';
import { removeEmptyValuesFromObject } from '@common/utils/objects/remove-empty-values-from-object';
import clsx from 'clsx';
export const Field = React.forwardRef((props, ref) => {
    const { children, 
    // Not every component that uses <Field> supports help text.
    description, errorMessage, descriptionProps = {}, errorMessageProps = {}, startAdornment, endAdornment, adornmentPosition, startAppend, endAppend, fieldClassNames, disabled, wrapperProps, } = props;
    return (_jsxs("div", { className: fieldClassNames.wrapper, ref: ref, ...wrapperProps, children: [_jsx(Label, { ...props }), _jsxs("div", { className: fieldClassNames.inputWrapper, children: [_jsx(Adornment, { direction: "start", className: fieldClassNames.adornment, position: adornmentPosition, children: startAdornment }), startAppend && (_jsx(Append, { style: fieldClassNames.append, disabled: disabled, children: startAppend })), children, endAppend && (_jsx(Append, { style: fieldClassNames.append, disabled: disabled, children: endAppend })), _jsx(Adornment, { direction: "end", className: fieldClassNames.adornment, position: adornmentPosition, children: endAdornment })] }), description && !errorMessage && (_jsx("div", { className: fieldClassNames.description, ...descriptionProps, children: description })), errorMessage && (_jsx("div", { className: fieldClassNames.error, ...errorMessageProps, children: errorMessage }))] }));
});
function Label({ labelElementType, fieldClassNames, labelProps, label, labelSuffix, labelSuffixPosition = 'spaced', required, }) {
    if (!label) {
        return null;
    }
    const ElementType = labelElementType || 'label';
    const labelNode = (_jsxs(ElementType, { className: fieldClassNames.label, ...labelProps, children: [label, required && _jsx("span", { className: "text-danger", children: " *" })] }));
    if (labelSuffix) {
        return (_jsxs("div", { className: clsx('mb-4 flex w-full gap-4', labelSuffixPosition === 'spaced' ? 'items-end' : 'items-center'), children: [labelNode, _jsx("div", { className: clsx('text-xs text-muted', labelSuffixPosition === 'spaced' ? 'ml-auto' : ''), children: labelSuffix })] }));
    }
    return labelNode;
}
function Append({ children, style, disabled }) {
    return React.cloneElement(children, {
        ...children.props,
        disabled: children.props.disabled || disabled,
        // make sure append styles are not overwritten with empty values
        ...removeEmptyValuesFromObject(style),
    });
}
