import {CustomPage} from '@common/admin/custom-pages/custom-page';
import {useEffect, useRef} from 'react';
import {highlightAllCode} from '@common/text-editor/highlight/highlight-code';
import React from 'react';
import ReactDOM from 'react-dom';

class Accordion extends React.Component <any, any> {
  constructor(props : any) {
  super(props);
  this.state = {
    display: 'none',
    transform: ''
  };
  
  this.toggle = this.toggle.bind(this);
}

toggle() {
  const newDisplay = this.state.display == 'block' ? 'none' : 'block';
  const newTransform = this.state.transform == 'rotate(180deg)' ? '' : 'rotate(180deg)';
  this.setState({
    display: newDisplay,
    transform: newTransform
  });
}

render() {
  return(
    <div className="accordion">
      <div className="accordionHeader" onClick={this.toggle}>
        <p>{this.props.accordionTitle}</p>
        <svg style={{ transform: this.state.transform }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
          <path d="M0-.75h24v24H0z" fill="none"/>
        </svg>
      </div>
      <div className="accordionContent" style={{ display: this.state.display }}>
        <p>{this.props.accordionContent}</p>
      </div>
    </div>
  );
}
}

interface CustomPageBodyProps {
  page: CustomPage;
}
export function CustomPageBody({page}: CustomPageBodyProps) {
  const bodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (bodyRef.current) {
      highlightAllCode(bodyRef.current);
    }
  }, []);

  function handleClick() {
    console.log("Button clicked");

    window.location.href = "/contact";

   
  }

  return (
    <div className="px-16 md:px-24">
      <div className="custom-page-body prose mx-auto my-50 dark:prose-invert">
        <h1>{page.title}</h1>

{/* Sadece sss için geçerli. */}
{window.location.href.indexOf("sss") > -1 && (
        <div className='accordions'>
      <Accordion
         accordionTitle="Linktr nedir?"
         accordionContent="Merhaba! Linktr, tüm sosyal medya hesaplarını ve içeriklerini tek bir bağlantı altında toplayabileceğin harika bir araçtır. Sayfanı kişiselleştir, takipçilerinle en sevdiğin içeriklerini paylaş ve çevrimiçi varlığını daha kolay yönet."
       />
      <Accordion
         accordionTitle="Linktr’yi nasıl kullanabilirim?"
         accordionContent="Linktr’yi kullanmak çok basit! Kayıt ol, sayfanı oluştur ve paylaşmak istediğin bağlantıları ekle. Daha sonra bu tek bağlantıyı takipçilerinle paylaşarak tüm içeriklerine kolayca ulaşmalarını sağlayabilirsin."
       />
      <Accordion
         accordionTitle="Linktr Pro nedir ve ne gibi özellikler sunar?"
         accordionContent="Linktr Pro, daha fazla özellik sunan ve dijital varlığını güçlendiren özel bir abonelik hizmetidir. Gelişmiş analizler, özel temalar, zamanlanmış yayınlar ve reklamsız deneyim gibi birçok avantaj sağlar. Detaylar için “Abonelik” bölümüne göz atabilirsin."
       />
       <Accordion
         accordionTitle="Aboneliğimi nasıl iptal edebilirim?"
         accordionContent="Aboneliğini iptal etmek istersen, hesabına giriş yaparak “Abonelik” bölümüne giderek iptal işlemini gerçekleştirebilirsin. İptal işlemi sonrasında, mevcut abonelik dönemin sona erene kadar Pro özelliklerine erişimin devam edecektir."
       />
        <Accordion
         accordionTitle="Linklerimi nasıl analiz edebilirim?"
         accordionContent="Link performansını analiz etmek için hesabına giriş yap ve “Analizler” bölümüne git. Burada, linklerinin tıklanma sayıları, tıklama oranları ve takipçi etkileşimleri gibi detaylı verilere ulaşabilirsin."
       />
        <Accordion
         accordionTitle="Linktr sayfamı nasıl kişiselleştirebilirim?"
         accordionContent="Sayfanı kişiselleştirmek için, sayfa seçeneklerine giderek fotoğrafını, biyografini ve temalarını düzenleyebilirsin. Ayrıca, Pro üyelik ile daha fazla kişiselleştirme seçeneğine erişebilir ve sayfanı tamamen sana özel hale getirebilirsin."
       />
        <Accordion
         accordionTitle="Yardım ve destek alabilir miyim?"
         accordionContent="Tabii ki! Herhangi bir sorunun veya sorunun varsa, bize ulaşmaktan çekinme. “İletişim” bölümünden mesajını iletebilir veya doğrudan bize email gönderebilirsin. Sana yardımcı olmak için buradayız!"
       />

       <div className='sss_proarea'>
          <img src="https://linkcrafter.com/storage/branding_media/sss-image.svg"></img>
          <h1>Bize bir şey mi söylemek istiyorsun?</h1>
          <p>Fikirlerini, sorularını ya da önerilerini duymaktan mutluluk duyarız. Bize kolayca ulaşabilir, dilersen geri bildirimde de bulunabilirsin.</p>
          <button onClick={handleClick}>İletişim</button>
       </div>
       
    </div>

)}

        <div
          ref={bodyRef}
          className="whitespace-pre-wrap break-words"
          dangerouslySetInnerHTML={{__html: page.body}}
        />
      </div>
    </div>
  );
}
