import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { Trans } from '@common/i18n/trans';
import { KeyboardArrowDownIcon } from '@common/icons/material/KeyboardArrowDown';
import clsx from 'clsx';
import { Button } from '@common/ui/buttons/button';
import { UnfoldMoreIcon } from '@common/icons/material/UnfoldMore';
import { UnfoldLessIcon } from '@common/icons/material/UnfoldLess';
export function IgnitionStackTrace({ trace, onSelectedIndexChange, selectedIndex, totalVendorGroups, }) {
    const [expandedVendorGroups, setExpandedVendorGroups] = useState([]);
    const allVendorGroupsExpanded = expandedVendorGroups.length === totalVendorGroups;
    return (_jsxs("div", { className: "max-w-440 border-r text-sm", children: [_jsx("div", { className: "border-b px-30 py-16", children: _jsx(Button, { variant: "outline", size: "2xs", startIcon: allVendorGroupsExpanded ? _jsx(UnfoldLessIcon, {}) : _jsx(UnfoldMoreIcon, {}), onClick: () => {
                        if (allVendorGroupsExpanded) {
                            setExpandedVendorGroups([]);
                        }
                        else {
                            setExpandedVendorGroups(trace
                                .map((frame, index) => ('vendorGroup' in frame ? index : -1))
                                .filter(index => index !== -1));
                        }
                    }, children: allVendorGroupsExpanded ? (_jsx(Trans, { message: "Collapse vendor frames" })) : (_jsx(Trans, { message: "Expand vendor frames" })) }) }), trace.map((frame, index) => {
                if ('vendorGroup' in frame) {
                    // vendor group is expanded, display all vendor frames
                    if (expandedVendorGroups.includes(index)) {
                        return (_jsx(Fragment, { children: frame.items.map((vendorFrame, index) => (_jsx(StackTrackItem, { frame: vendorFrame, onClick: () => onSelectedIndexChange(vendorFrame.flatIndex), isSelected: selectedIndex === vendorFrame.flatIndex }, `vendor-${index}`))) }, index));
                    }
                    // vendor group is collapsed, only show vendor group header
                    return (_jsxs("div", { className: "flex cursor-pointer items-center gap-4 border-b px-30 py-16 hover:bg-hover", onClick: () => setExpandedVendorGroups(prev => [...prev, index]), children: [_jsx(Trans, { message: ":count vendor [one frame|other frames]", values: { count: frame.items.length } }), _jsx(KeyboardArrowDownIcon, { className: "text-muted" })] }, index));
                }
                // app frame item
                return (_jsx(StackTrackItem, { frame: frame, onClick: () => onSelectedIndexChange(frame.flatIndex), isSelected: selectedIndex === frame.flatIndex }, index));
            })] }));
}
function StackTrackItem({ frame, onClick, isSelected }) {
    return (_jsxs("div", { onClick: onClick, className: clsx('cursor-pointer border-b px-30 py-16', isSelected ? 'bg-danger text-on-primary' : 'hover:bg-danger/10'), children: [_jsx(IgnitionFilePath, { frame: frame }), _jsx("div", { className: "font-semibold", children: frame.method })] }));
}
export function IgnitionFilePath({ frame }) {
    return (_jsxs("div", { className: "inline-flex flex-wrap items-baseline", children: [frame.path.map((part, index) => frame.path.length - 1 === index ? (_jsx("div", { className: "font-semibold", children: part }, index)) : (_jsxs("div", { children: [part, "/"] }, index))), _jsxs("div", { children: [":", frame.lineNumber] })] }));
}
