import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { applyThemeToDom } from '../ui/themes/utils/apply-theme-to-dom';
import { ThemeSelectorContext, } from '../ui/themes/theme-selector-context';
import { useSettings } from './settings/use-settings';
import { useBootstrapData } from './bootstrap-data/bootstrap-data-context';
import { useCookie } from '@common/utils/hooks/use-cookie';
const STORAGE_KEY = 'be-active-theme';
export function ThemeProvider({ children }) {
    const { themes } = useSettings();
    const canChangeTheme = themes?.user_change;
    const { data } = useBootstrapData();
    const allThemes = useMemo(() => data.themes.all || [], [data.themes.all]);
    const initialThemeId = data.themes.selectedThemeId || undefined;
    const [selectedThemeId, setSelectedThemeId] = useCookie(STORAGE_KEY, `${initialThemeId}`);
    let selectedTheme = canChangeTheme
        ? allThemes.find(t => t.id == selectedThemeId)
        : allThemes.find(t => t.id == themes?.default_id);
    if (!selectedTheme) {
        selectedTheme = allThemes[0];
    }
    const contextValue = useMemo(() => {
        return {
            allThemes,
            selectedTheme: selectedTheme,
            selectTheme: (id) => {
                if (!canChangeTheme)
                    return;
                const theme = findTheme(allThemes, id);
                if (theme) {
                    setSelectedThemeId(`${theme.id}`);
                    applyThemeToDom(theme);
                }
            },
        };
    }, [allThemes, selectedTheme, setSelectedThemeId, canChangeTheme]);
    return (_jsx(ThemeSelectorContext.Provider, { value: contextValue, children: children }));
}
function findTheme(themes, id) {
    return themes.find(t => {
        if (id === 'light') {
            return t.default_light === true;
        }
        if (id === 'dark') {
            return t.default_dark === true;
        }
        return t.id === id;
    });
}
