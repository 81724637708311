import {Link} from 'react-router-dom';
import {ReactNode} from 'react';
import {AuthLayoutFooter} from './auth-layout-footer';
import {useIsDarkMode} from '@common/ui/themes/use-is-dark-mode';
// import authBgSvg from './auth-bg.svg';
import {useTrans} from '@common/i18n/use-trans';
import {useSettings} from '@common/core/settings/use-settings';

interface AuthPageProps {
  heading?: ReactNode;
  message?: ReactNode;
  children: ReactNode;
}
export function AuthLayout({heading, children, message}: AuthPageProps) {
  const {branding} = useSettings();
  const isDarkMode = useIsDarkMode();
  const {trans} = useTrans();



  function handleClick() {
    console.log("Button clicked");

    if (window.location.href.indexOf("login") > -1) {
      if (window.location.href.indexOf("linkcrafter") > -1) {
        window.location.href = "https://linkcrafter.com/";
        return false;
      }else {
        window.location.href = "http://localhost/";
        return false;
      }
    }
    else {
      window.history.go(-1); 
      return false;
    }

   
  }



  const css = `
    #login_header_text {
        color: var(--Button-Purple, #7B7BF7);
leading-trim: both;
text-edge: cap;

/* H1 */
font-family: Onest;
font-size: 36px;
font-style: normal;
font-weight: 800;
line-height: normal;
letter-spacing: -0.72px;
    }

    #back_button {
    color: var(--Black, #000);
leading-trim: both;
text-edge: cap;
text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01);

/* Button */
font-family: Onest;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
display: flex;
padding: 6px;
align-items: center;
gap: 6px;
align-self: stretch;
position: fixed;
    top: 20px;
    left: 5px;
    }

    #root > main > div > form > div.mb-32.text-sm > label {
      color: var(--Black, #000);
leading-trim: both;
text-edge: cap;

/* General */
font-family: Onest;4
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
    }

       #root > main > div > form > div.mb-32.text-sm > div > input {
      color: var(--Black, #000);
leading-trim: both;
text-edge: cap;

/* General */
font-family: Onest;4
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
    }

    #root > main > div > form > div.mb-12.text-sm > div.mb-4.flex.w-full.gap-4.items-end > label {
            color: var(--Black, #000);
leading-trim: both;
text-edge: cap;

/* General */
font-family: Onest;4
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
    }

    #forgot_password {
    color: var(--Button-Purple, #7B7BF7);
text-align: right;
leading-trim: both;
text-edge: cap;

/* Button */
font-family: Onest;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
    text-align: center;
    margin-bottom:10px;
    }

  #forgot_password_label {
  color: var(--Button-Purple, #7B7BF7);
  }

  #root > main > div > form > div.mb-32.text-sm > div > input {
    border-radius: 6px;
background: var(--Light, #F4F6F0);
min-height: 48px;

  }

  #root > main > div > form > div.mb-12.text-sm > div > input {
   border-radius: 6px;
background: var(--Light, #F4F6F0);
min-height: 48px;
color: var(--Black, #000);
    leading-trim: both;
    text-edge: cap;
    font-family: Onest !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  #root > main > div > form > div.mb-12.text-sm > label {
  color: var(--Black, #000);
    leading-trim: both;
    text-edge: cap;
    font-family: Onest;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  }

#root > main > div.mx-auto.w-full.max-w-440.rounded-lg > form > div.mb-12.text-sm > div > input {
    color: var(--Black, #000);
    leading-trim: both;
    text-edge: cap;
    font-family: Onest !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
}

#login_button {
  display: flex;
height: 36px;
padding: 6px 24px;
justify-content: center;
align-items: center;
gap: 6px;
align-self: stretch;
border-radius: 200px;
background: var(--Button-Purple, #7B7BF7);
color: var(--White, #FFF);
leading-trim: both;
text-edge: cap;
text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01);

/* Button */
font-family: Onest;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
}

#register_label {
display: flex;
justify-content: center;
align-items: center;
gap: 6px;
align-self: stretch;
color: var(--Black, #000);
leading-trim: both;
text-edge: cap;

/* Button */
font-family: Onest;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
margin-top:20px;
}

#register_button {
color: var(--Button-Purple, #7B7BF7);
leading-trim: both;
text-edge: cap;

/* Button */
font-family: Onest;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
}
    
`;



  return (
    
    
    <main
      className="flex h-screen flex-col items-center overflow-y-auto bg-alt px-14 pt-70 dark:bg-none md:px-10vw" 
    >
  
      <style>{css}</style>
      <button onClick={handleClick} id='back_button'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"> <path d="M7.9499 9.53261C7.8796 9.46288 7.8238 9.37993 7.78573 9.28854C7.74765 9.19714 7.72805 9.09912 7.72805 9.00011C7.72805 8.9011 7.74765 8.80307 7.78573 8.71167C7.8238 8.62028 7.8796 8.53733 7.9499 8.46761L11.3924 5.03261C11.4627 4.96288 11.5185 4.87993 11.5566 4.78854C11.5946 4.69714 11.6142 4.59912 11.6142 4.50011C11.6142 4.4011 11.5946 4.30307 11.5566 4.21167C11.5185 4.12028 11.4627 4.03733 11.3924 3.96761C11.2519 3.82792 11.0618 3.74951 10.8636 3.74951C10.6655 3.74951 10.4754 3.82792 10.3349 3.96761L6.89239 7.41011C6.47104 7.83198 6.23438 8.40385 6.23438 9.00011C6.23438 9.59636 6.47104 10.1682 6.89239 10.5901L10.3349 14.0326C10.4746 14.1712 10.6631 14.2493 10.8599 14.2501C10.9586 14.2507 11.0564 14.2318 11.1478 14.1944C11.2392 14.1571 11.3223 14.1021 11.3924 14.0326C11.4627 13.9629 11.5185 13.8799 11.5566 13.7885C11.5946 13.6971 11.6142 13.5991 11.6142 13.5001C11.6142 13.4011 11.5946 13.3031 11.5566 13.2117C11.5185 13.1203 11.4627 13.0373 11.3924 12.9676L7.9499 9.53261Z" fill="black"/></svg> Geri Dön</button>
      <Link 
        to="/"
        className="mb-40 block flex-shrink-0"
        aria-label={trans({message: 'Go to homepage'})}
      >
       
      </Link>
      <div className="mx-auto w-full max-w-440 rounded-lg">
        {heading &&  <h1 id='login_header_text'>Link TR’ye hoş geldin!</h1>} <br></br>
        {children}
      </div> 
      {message && <div className="mt-36 text-sm">{message}</div>}
      <AuthLayoutFooter />
    </main>
  );
}


