import { jsx as _jsx } from "react/jsx-runtime";
import { SocialsList, SocialsType, } from '@app/dashboard/biolink/biolink-editor/content/widgets/socials-widget/socials-list';
import { isAbsoluteUrl } from '@common/utils/urls/is-absolute-url';
import clsx from 'clsx';
import { IconButton } from '@common/ui/buttons/icon-button';
export function SocialsWidgetRenderer({ widget, variant, }) {
    return (_jsx("div", { className: clsx('flex flex-wrap items-center gap-y-8', variant === 'editor'
            ? 'gap-x-14 mt-4 text-muted'
            : 'gap-x-2 mt-20 mb-26 justify-center'), children: Object.entries(widget.config).map(([type, uri]) => {
            const Icon = SocialsList[type].icon;
            if (!Icon)
                return null;
            if (variant === 'editor') {
                return _jsx(Icon, {}, type);
            }
            return (_jsx(IconButton, { className: "flex-shrink-0", elementType: "a", href: buildUrl(type, uri), children: _jsx(Icon, {}) }, type));
        }) }));
}
function buildUrl(socialsType, uri) {
    if (!uri || isAbsoluteUrl(uri)) {
        return uri;
    }
    // only remove @ from the begging of string (Twitter and Instagram handle for example)
    if (socialsType === SocialsType.Twitter) {
        return `https://twitter.com/${uri.replace('@', '')}`;
    }
    else if (socialsType === SocialsType.Instagram) {
        return `https://instagram.com/${uri.replace('@', '')}`;
    }
    else if (socialsType === SocialsType.Tiktok) {
        return `https://tiktok.com/${uri}`;
    }
    else if (socialsType === SocialsType.Mail) {
        return `mailto:${uri}`;
    }
    else if (socialsType === SocialsType.Whatsapp) {
        return `https://api.whatsapp.com/send?phone=${uri}`;
    }
    return uri;
}
