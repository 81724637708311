export default class SpotifyUri {
    uri;
    constructor(uri) {
        this.uri = uri;
    }
    static is(v) {
        return Boolean(typeof v === 'object' && typeof v.uri === 'string');
    }
    toEmbedURL() {
        return `https://embed.spotify.com/?uri=${this.toURI()}`;
    }
    toOpenURL() {
        return `https://open.spotify.com${this.toURL()}`;
    }
    toPlayURL() {
        return `https://play.spotify.com${this.toURL()}`;
    }
}
