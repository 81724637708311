import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
export function DialogFooter(props) {
    const { children, startAction, className, dividerTop, padding, size } = props;
    return (_jsxs("div", { className: clsx(className, dividerTop && 'border-t', getPadding(props), 'flex items-center gap-10 flex-shrink-0'), children: [_jsx("div", { children: startAction }), _jsx("div", { className: "ml-auto flex items-center gap-10", children: children })] }));
}
function getPadding({ padding, size }) {
    if (padding) {
        return padding;
    }
    switch (size) {
        case 'xs':
            return 'p-14';
        case 'sm':
            return 'p-18';
        default:
            return 'px-24 py-20';
    }
}
