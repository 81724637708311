import {Fragment, ReactNode} from 'react';
import {Trans} from '@common/i18n/trans';

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
}
export function TwoFactorStepperLayout({
  title,
  subtitle,
  description,
  actions,
  children,
}: Props) {
  if (!subtitle) {
    subtitle = (
      <Trans message="İki faktörlü kimlik doğrulama etkinleştirildiğinde, kimlik doğrulama sırasında güvenli, bir doğrulama kodu girmeniz istenecektir. Bu belirteci telefonunuzun Google Authenticator uygulamasından alabilirsiniz." />
    );
  }
  return (
    <Fragment>
       <div className='accountframe'>
          <div className='accountframeInside'>
              <div className='accountframeInsideTitle'>İki faktörlü doğrulama</div>
              <p className='acccountframeParag'>Hesabına bir güvenlik katmanı daha eklemek istiyorsan bu seçeneği aktif et. Google Authenticator vb. uygulamaları kullanabilirsin.</p>
              <p className="text-sm font-medium my-16">{description}</p>
              {children}
          </div>
          {/* <input className="absolute left-0 top-0 h-24 w-24 appearance-none overflow-hidden rounded outline-none ring-inset transition-shadow focus-visible:ring" type="checkbox" aria-checked="false" name="2factorcheckbox" value=""/> */}
          {actions}
        </div>

      {/* <div className="text-base font-medium mb-16">{title}</div>
       <div className="text-sm mb-24">{subtitle}</div> 
      <p className="text-sm font-medium my-16">{description}</p>
      {children}
      <div className="flex items-center gap-12">{actions}</div> */}
    </Fragment>
  );
}
