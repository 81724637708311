import { jsx as _jsx } from "react/jsx-runtime";
import { useSettings } from '@common/core/settings/use-settings';
import { useCallback, useEffect, useRef, useState } from 'react';
import { buildLongUrlWithUtm } from '@app/dashboard/links/utils/build-long-url-with-utm';
import { Trans } from '@common/i18n/trans';
import { Button } from '@common/ui/buttons/button';
export function RedirectCountdownButton({ link, ...buttonProps }) {
    const { links: { redirect_time = 0 }, // in seconds
     } = useSettings();
    const intervalRef = useRef(null);
    const countDownRef = useRef(redirect_time);
    const [countdown, setCountdown] = useState(countDownRef.current);
    const redirectToLongUrl = useCallback(() => {
        window.location.href = buildLongUrlWithUtm(link);
    }, [link]);
    useEffect(() => {
        if (!redirect_time) {
            return;
        }
        intervalRef.current = setInterval(() => {
            countDownRef.current--;
            if (countDownRef.current <= 0 && intervalRef.current) {
                clearInterval(intervalRef.current);
                redirectToLongUrl();
            }
            setCountdown(countDownRef.current);
        }, 1000);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [redirect_time, setCountdown, redirectToLongUrl]);
    const buttonText = countdown > 0 ? (_jsx(Trans, { message: "Redirect in :seconds", values: { seconds: countdown } })) : (_jsx(Trans, { message: "Go to link" }));
    return (_jsx(Button, { ...buttonProps, className: "min-w-128", onClick: () => {
            if (countdown <= 0) {
                redirectToLongUrl();
            }
        }, children: buttonText }));
}
