import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import clsx from 'clsx';
export const DialogBody = forwardRef((props, ref) => {
    const { children, className, padding, size, ...domProps } = props;
    return (_jsx("div", { ...domProps, ref: ref, className: clsx(className, getPadding(props), 'overflow-y-auto overflow-x-hidden overscroll-contain text-sm flex-auto'), children: children }));
});
function getPadding({ size, padding }) {
    if (padding) {
        return padding;
    }
    switch (size) {
        case 'xs':
            return 'p-14';
        case 'sm':
            return 'p-18';
        default:
            return 'px-24 py-20';
    }
}
