import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { removeProtocol } from '@common/utils/urls/remove-protocol';
import { RemoteFavicon } from '@common/ui/remote-favicon';
import { LinkStyle } from '@common/ui/buttons/external-link';
import clsx from 'clsx';
import { LinkClipboardButton } from '@app/dashboard/links/sharing/link-clipboard-button';
import { InfiniteScrollSentinel } from '@common/ui/infinite-scroll/infinite-scroll-sentinel';
import { Fragment } from 'react';
import { Skeleton } from '@common/ui/skeleton/skeleton';
import { AnimatePresence, m } from 'framer-motion';
import { opacityAnimation } from '@common/ui/animation/opacity-animation';
import { IllustratedMessage } from '@common/ui/images/illustrated-message';
import shareLink from '@app/dashboard/links/share-link.svg';
import { SvgImage } from '@common/ui/images/svg-image/svg-image';
import { Trans } from '@common/i18n/trans';
import { useInfiniteData } from '@common/ui/infinite-scroll/use-infinite-data';
export function LinkGroupRenderer({ linkGroup }) {
    const query = useInfiniteData({
        willSortOrFilter: true,
        queryKey: ['link-group', linkGroup.id, 'links'],
        endpoint: `link-group/${linkGroup.id}/links`,
        paginate: 'simple',
    });
    let content;
    if (query.isLoading) {
        content = _jsx(Skeletons, {}, "skeletons");
    }
    else if (query.noResults) {
        content = (_jsx(IllustratedMessage, { className: "mt-80", image: _jsx(SvgImage, { src: shareLink }), title: _jsx(Trans, { message: "Nothing to show" }), description: _jsx(Trans, { message: "This group does not have any links yet" }) }, "illustration"));
    }
    else {
        content = _jsx(LinkList, { data: query.items }, "linkList");
    }
    return (_jsxs("div", { className: "bg-alt flex flex-col min-h-screen", children: [_jsx(Navbar, { menuPosition: "link-page-navbar", className: "sticky top-0 flex-shrink-0" }), _jsxs("div", { className: "container mx-auto px-24 py-40 flex-auto", children: [_jsx("h1", { className: "text-3xl mb-40", children: linkGroup.name }), _jsxs("div", { children: [_jsx(AnimatePresence, { initial: false, mode: "wait", children: content }), _jsx(InfiniteScrollSentinel, { query: query })] })] })] }));
}
function LinkList({ data }) {
    return (_jsx(Fragment, { children: data?.map(link => (_createElement(m.div, { ...opacityAnimation, key: link.id, className: "p-20 shadow rounded-lg mb-20 bg-paper" },
            _jsxs("div", { className: "flex items-center gap-8", children: [_jsx(RemoteFavicon, { url: link.long_url }), _jsx("a", { target: "_blank", href: link.long_url, className: clsx(LinkStyle, 'font-medium'), rel: "noreferrer", children: removeProtocol(link.long_url) })] }),
            _jsx(LinkClipboardButton, { link: link, variant: "text", className: "text-sm hover:underline" }),
            link.description && (_jsx("div", { className: "text-muted text-sm mt-14", children: link.description }))))) }));
}
function Skeletons() {
    const skeletons = Array.from(Array(10).keys());
    return (_createElement(m.div, { ...opacityAnimation, key: "skeleton" }, skeletons.map(skeleton => (_jsxs(m.div, { className: "p-20 shadow rounded-lg mb-20 bg-paper", children: [_jsx(Skeleton, { className: "text-sm mb-14" }), _jsx(Skeleton, { className: "text-xs" }), _jsx(Skeleton, { className: "text-xs" })] }, skeleton)))));
}
