import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, cloneElement, forwardRef, isValidElement, useId, } from 'react';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import { getInputFieldClassNames } from '../input-field/get-input-field-class-names';
export const RadioGroup = forwardRef((props, ref) => {
    const style = getInputFieldClassNames(props);
    const { label, children, size, className, orientation = 'horizontal', disabled, required, invalid, errorMessage, description, } = props;
    const labelProps = {};
    const id = useId();
    const name = props.name || id;
    return (_jsxs("fieldset", { "aria-describedby": description ? `${id}-description` : undefined, ref: ref, className: clsx('text-left', className), children: [label && (_jsx("legend", { className: style.label, ...labelProps, children: label })), _jsx("div", { className: clsx('flex', label ? 'mt-6' : 'mt-0', orientation === 'vertical' ? 'flex-col gap-10' : 'flex-row gap-16'), children: Children.map(children, child => {
                    if (isValidElement(child)) {
                        return cloneElement(child, {
                            name,
                            size,
                            invalid: child.props.invalid || invalid || undefined,
                            disabled: child.props.disabled || disabled,
                            required: child.props.required || required,
                        });
                    }
                }) }), description && !errorMessage && (_jsx("div", { className: style.description, id: `${id}-description`, children: description })), errorMessage && _jsx("div", { className: style.error, children: errorMessage })] }));
});
export function FormRadioGroup({ children, ...props }) {
    const { fieldState: { error }, } = useController({
        name: props.name,
    });
    return (_jsx(RadioGroup, { errorMessage: error?.message, ...props, children: children }));
}
